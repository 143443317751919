import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import EllipsisVerticalIcon from '@pelckmans/business-components/icons/EllipsisVertical';

export default function OptionsButton({ className, onClick }) {
  const { t } = useTranslation();

  return (
    <button type="button" className={className} onClick={onClick} data-testid="btn-options" title={t('buttons.optionsButton.tooltip')}>
      <EllipsisVerticalIcon />
    </button>
  );
}

OptionsButton.propTypes = {
  className: string,
  onClick: func.isRequired,
};
