import React, { useState, useEffect } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import PDFViewer from '../../../../../components/pdf-viewer';
import api from '../../../../../services/api';
import ImageViewer from '../../../../../components/image-viewer';
import JWPlayer from '../common/JWPlayer';

const File = ({ medialinkId, file, type, imagePreview, onFullScreenClick, dialogSize, close, isFullScreen, modalPosition, jwplayer }) => {
  const [signedUrl, setSignedUrl] = useState('');

  useEffect(() => {
    const getSignedUrl = async () => {
      const { data } = await api.get(`/medialinks/${medialinkId}/signedurl?extendedTimeValidity=true${file.s3file.preview ? '&preview=true' : ''}`);
      setSignedUrl(data.url);
    };
    getSignedUrl();
  }, [medialinkId, file.s3file]);

  switch (type) {
    case 'image':
      return signedUrl && <img data-testid="img-medialink" src={signedUrl} alt="" className="img--fit" />;
    case 'audio':
    case 'video':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return signedUrl && <JWPlayer id={medialinkId} audioOnly={type === 'audio'} {...(jwplayer?.ready ? { playlist: signedUrl } : { file: signedUrl })} />;
    default:
      if (imagePreview) {
        return (
          signedUrl && (
            <ImageViewer
              close={close}
              dialogSize={dialogSize}
              onFullScreenClick={onFullScreenClick}
              id={medialinkId}
              src={signedUrl}
              isFullScreen={isFullScreen}
              modalPosition={modalPosition}
            />
          )
        );
      }

      return signedUrl && <PDFViewer src={signedUrl} target={file.pdfLinkTarget} />;
  }
};

File.propTypes = {
  medialinkId: string.isRequired,
  file: shape({
    s3file: shape({
      id: string,
    }),
    pdfLinkTarget: string,
  }).isRequired,
  type: string.isRequired,
  jwplayer: shape({
    ready: bool.isRequired,
  }),
  imagePreview: bool,
  onFullScreenClick: func,
  dialogSize: shape({ width: number, height: number }).isRequired,
  close: func.isRequired,
  isFullScreen: bool.isRequired,
  modalPosition: shape({ x: number, y: number }),
};

export default File;
