import React from 'react';

import { arrayOf, bool, oneOf, shape, string, any } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';

const isUserMediaShared = (userMedia, isOwner) => {
  if (isOwner) return userMedia.shared;

  return userMedia.shares && userMedia.shares.length > 0;
};

function SharedBadge({ isOwner = false, label }) {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-list__label" data-testid="share-label">
      <i className="bc-icon bc-icon-share" role="img" aria-label="Shared" />
      <span>{isOwner ? t('drawer.material.default_share_label') : label || t('drawer.material.default_share_label')}</span>
    </div>
  );
}

SharedBadge.propTypes = {
  isOwner: bool,
  label: string,
};

function AssignmentSharedBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-list__label pbb-label--orange" data-testid="assignment-share-label">
      <i className="bc-icon bc-icon-assignment" role="img" />
      <span>{t('drawer.material.assignment_label')}</span>
    </div>
  );
}

export default function MaterialInfo({ material, isOwner = false, materialType }) {
  const isShared = isUserMediaShared(material, isOwner);
  const isSharedAsAssignment = materialType === 'user-material' ? Boolean(material.assignment) : Boolean(material.assignments && material.assignments.length);

  return (
    <>
      <div className="pbb-flex">
        <MaterialIcon contentType={material.contentType} materialType={materialType} className="pbb-list__link-icon" />
        <div className="pbb-list__link-text">{material.name}</div>
      </div>
      {(isShared || isSharedAsAssignment) && (
        <div className="pbb-list__labels">
          {isSharedAsAssignment && <AssignmentSharedBadge />}
          {isShared && <SharedBadge isOwner={isOwner} label={material.shares ? material.shares[0]?.label : undefined} />}
        </div>
      )}
    </>
  );
}

MaterialInfo.propTypes = {
  material: shape({
    name: string.isRequired,
    assignment: any,
    assignments: arrayOf(any),
    shared: bool,
    shares: arrayOf(
      shape({
        label: string,
      }),
    ),
    icon: string,
  }).isRequired,
  materialType: oneOf(['user-material', 'medialink']).isRequired,
  isOwner: bool,
};
