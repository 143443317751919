/**
 * @description Default array that is stable and can be shared across components.
 * This avoids unnecessary re-renders due to defaults for functions changing reference on every render.
 */
export const EMPTY_ARRAY = [];
/**
 * @description Default object that is stable and can be shared across components.
 * This avoids unnecessary re-renders due to defaults for functions changing reference on every render.
 */
export const EMPTY_OBJECT = {};
/**
 * @description Default function that is stable and can be shared across components.
 * This avoids unnecessary re-renders due to defaults for functions changing reference on every render.
 */
export const EMPTY_FUNCTION = () => {};
