import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { func, string, arrayOf } from 'prop-types';
import EyeStrikethroughIcon from '@pelckmans/business-components/icons/EyeStrikethrough';
import EyeIcon from '@pelckmans/business-components/icons/Eye';
import { UserSettingsContext } from '../../../../context/user-settings-context';
import { addUserCategoryToHide, removeUserCategoryToHide } from '../../../../../../actions/user';
import { EMPTY_ARRAY } from '../../../../../../utils/stable-default-props';

const CategoryFilter = ({ category, moduleId, materialCategoriesToHide = EMPTY_ARRAY, dispatch }) => {
  const { setShowOnlyFavoriteMaterial } = useContext(UserSettingsContext);
  const isCategoryHidden = materialCategoriesToHide.indexOf(category) > -1;
  const [t] = useTranslation();
  const filterButtonClicked = useCallback(() => {
    if (isCategoryHidden) {
      dispatch(removeUserCategoryToHide(moduleId, category));
    } else {
      dispatch(addUserCategoryToHide(moduleId, category));
    }
    setShowOnlyFavoriteMaterial(false); // If only the favourite media links are shown (see below) and a new type filter is applied, the favourite filter is reset.
  }, [dispatch, moduleId, category, isCategoryHidden, setShowOnlyFavoriteMaterial]);

  return (
    <li className={classNames('pbb-material__settings-item', { 'pbb-material__settings-item--hide': isCategoryHidden })}>
      <div className="pbb-material__title">{category}</div>
      <button
        type="button"
        className="pbb-material__toggler"
        onClick={filterButtonClicked}
        title={isCategoryHidden ? t('drawer.material.filterSettings.buttons.showCategory.tooltip') : t('drawer.material.filterSettings.buttons.hideCategory.tooltip')}
      >
        {isCategoryHidden ? <EyeStrikethroughIcon /> : <EyeIcon />}
      </button>
    </li>
  );
};

CategoryFilter.propTypes = {
  dispatch: func.isRequired,
  category: string.isRequired,
  moduleId: string.isRequired,
  materialCategoriesToHide: arrayOf(string),
};

export default connect()(CategoryFilter);
