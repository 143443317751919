/*  eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import { string, func, node, bool, arrayOf, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';

const getModalTitle = (icon, title) => {
  const iconComp = typeof icon === 'string' ? <i className={icon} data-testid="dialog-header-icon" /> : icon;

  return (
    <div className="pbb-modal__title" title={title}>
      {icon ? iconComp : null}
      <span>{title}</span>
    </div>
  );
};

const DialogHeader = ({ title, breadcrumbs, icon, close, draggable = false, action }) => {
  const [t] = useTranslation();

  const modalTitle = getModalTitle(breadcrumbs ? undefined : icon, title);

  return (
    <div className={classNames('pbb-modal__header', { 'pbb-modal__header--draggable': draggable })}>
      {draggable ? (
        <>
          <div className={classNames('pbb-modal__title-wrapper', { draggable: !action })}>
            {action && draggable ? <span className="draggable">{modalTitle}</span> : <>{modalTitle}</>}
            {action && <div className="pbb-modal__action">{action}</div>}
          </div>
        </>
      ) : breadcrumbs ? (
        <>
          <div className="pbb-modal__headerinfo-wrapper">
            {getModalTitle(null, title)}
            <div className="pbb-modal__subtitle" data-testid="breadcrumbs">
              {breadcrumbs.map((b, index) => (
                // index is fine as key, never changes order
                // eslint-disable-next-line react/no-array-index-key
                <div key={`breadcrumb-${index}`} className="pbb-modal__header-info">
                  {b}
                </div>
              ))}
            </div>
          </div>
          {action}
        </>
      ) : (
        <>
          {getModalTitle(icon, title)}
          {action}
        </>
      )}

      {close && (
        <button data-testid="close" type="button" className="close" data-dismiss="pbb-modal" aria-label="Close" onClick={close} title={t('options.close')}>
          <i className="icon-submodule-close" />
        </button>
      )}
    </div>
  );
};

DialogHeader.propTypes = {
  title: string.isRequired,
  breadcrumbs: arrayOf(node),
  icon: oneOfType([string, node]),
  close: func,
  action: node,
  draggable: bool,
};

export default DialogHeader;
