import { any, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

import { closeMinisite } from '../../../../actions/dialog';
import dialogTypes from '../../../../enums/dialogTypes';
import { getMedialinksChooserData, getOpenDialogs, getOpenMaterialAssignment, getOpenMinisite } from '../../../../selectors/dialogs';
import { getCurrentDigibook } from '../../../../selectors/digibooks';
import { formatTeacherName } from '../../../../utils/nameFormatting';
import { EMPTY_ARRAY } from '../../../../utils/stable-default-props';
import { UserSettingsContext } from '../../context/user-settings-context';
import AnnotationSetsManagementModal from './annotationSetsManagementModal';
import MaterialAssignmentModal from './components/MaterialAssignmentModal';
import MediaDialogs from './media-dialogs';
import MediaLinkChooser from './mediaDialogs/chooser';
import WebsiteModal from './mediaDialogs/website-modal';
import NotesModal from './notes/notes-modal';
import MedialinkOptionsDialog from './options/medialinkOptions';
import TocNodeOptionsModal from './options/tocNodeOptions';
import PrintPageRangeModal from './PrintPageRangeModal';
import WhitepageOptionsModal from './whitepages/whitepage-options-modal';

export const Dialogs = ({ openDialogs = EMPTY_ARRAY, superModuleId, medialinksChooser, openMinisite, digibook, dispatch, openMaterialAssignment }) => {

  // TODO dialogTypes are only for modals. so this can be refactored
  function renderDialogByType({ id, dialogType, ...data }) {
    switch (dialogType) {
      case dialogTypes.OPTIONS: {
        return <MedialinkOptionsDialog key={`${id}_${dialogType}`} medialinkId={id} superModuleId={superModuleId} activeTab={data.activeTab} />;
      }
      case dialogTypes.PRINT_PAGE: {
        return (
          <PrintPageRangeModal
            digibook={digibook}
            key={dialogType}
            dispatch={dispatch}
            activeNodes={data.activeNodes}
            visiblePages={data.visiblePages}
            teacherFeaturesEnabled={digibook.teacherFeaturesEnabled}
          />
        );
      }
      case dialogTypes.TOCNODE_OPTIONS: {
        return <TocNodeOptionsModal digibook={digibook} key={dialogType} node={data.node} dispatch={dispatch} activeTab={data.activeTab} />;
      }

      case dialogTypes.NOTE_OPTIONS: {
        return <NotesModal key={dialogType} tocNodeId={data.tocNodeId} dispatch={dispatch} noteId={id} activeTab={data.activeTab} />;
      }

      case dialogTypes.WHITEPAGE_OPTIONS: {
        return <WhitepageOptionsModal key={dialogType} tocNodeId={data.tocNodeId} dispatch={dispatch} whitepageId={id} activeTab={data.activeTab} />;
      }

      case dialogTypes.ANNOTATION_SETS_MANAGEMENT: {
        return <AnnotationSetsManagementModal key={dialogType} />;
      }

      default: {
        return null;
      }
    }
  }

  const mediaDialogs = openDialogs.filter(x => !x.dialogType);
  const modals = openDialogs.filter(x => x.dialogType)

  const { sidebarAnchor, activeDrawer } = useContext(UserSettingsContext);

  return (
    <>
      {modals.length > 0 && modals.map(dialog => renderDialogByType(dialog))}
      {mediaDialogs.length > 0 && <MediaDialogs sidebarAnchor={sidebarAnchor} activeDrawer={activeDrawer} dialogsInfo={mediaDialogs} superModuleId={superModuleId} />}
      {medialinksChooser && (
        <MediaLinkChooser
          key={medialinksChooser.linkAreaId}
          linkAreaId={medialinksChooser.linkAreaId}
          title={medialinksChooser.title}
          medialinks={medialinksChooser.medialinks}
          mousePosition={medialinksChooser.mousePosition}
        />
      )}
      {openMinisite &&
        createPortal(
          <WebsiteModal
            width={openMinisite.medialink.miniSite.width}
            height={openMinisite.medialink.miniSite.height}
            name={openMinisite.medialink.name}
            iconClassName={openMinisite.medialink.icon}
            reload={openMinisite.medialink.miniSite.reload}
            url={openMinisite.miniSiteUrl}
            onClose={() => {
              dispatch(closeMinisite(openMinisite.medialink.id));
            }}
          />,
          document.body,
        )}
      {openMaterialAssignment && (
        <div className={`material-assignment-modal-container pbb-panel--${sidebarAnchor}`}>
          <MaterialAssignmentModal
            dispatch={dispatch}
            material={openMaterialAssignment.material}
            assignment={openMaterialAssignment.assignment}
            sharedBy={formatTeacherName(openMaterialAssignment.owner)}
          />
        </div>
      )}
    </>
  );
};

Dialogs.propTypes = {
  // Own Props
  superModuleId: string,

  // Connected Props
  openDialogs: arrayOf(
    shape({
      id: string,
      isInFront: bool,
      dialogType: string,
      data: shape({
        visiblePages: arrayOf(number),
        activeNodes: arrayOf(object),
        node: object,
      }),
    }),
  ),
  openMinisite: shape({
    medialink: any,
    miniSiteUrl: string,
  }),
  medialinksChooser: shape({
    title: string,
    medialinks: arrayOf(shape({ id: string, name: string, icon: string })),
    mousePosition: shape({ x: number, y: number }),
  }),
  digibook: shape({
    teacherFeaturesEnabled: bool,
    answerLayer: string,
    totalPages: number,
  }).isRequired,
  openMaterialAssignment: shape({
    material: MaterialAssignmentModal.propTypes.material,
    assignment: MaterialAssignmentModal.propTypes.assignment,
  }),
  dispatch: func.isRequired,
};

const mapStateToProps = state => ({
  openDialogs: getOpenDialogs(state),
  openMinisite: getOpenMinisite(state),
  medialinksChooser: getMedialinksChooserData(state),
  digibook: getCurrentDigibook(state),
  openMaterialAssignment: getOpenMaterialAssignment(state),
});

export default connect(mapStateToProps)(Dialogs);
