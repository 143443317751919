import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { shape, bool, string, arrayOf, number } from 'prop-types';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import { ProgressionBar } from '@pelckmans/business-components/components/progression-bar';

import { getFileType } from '../../../../../utils/filetype';
import { getExternalMediaType } from '../../../../../utils/externalMediaType';
import { getModuleById } from '../../../../../selectors/module';
import { getTeacherFeaturesEnabledFor } from '../../../../../selectors/digibooks';
import { getTOCNodeById } from '../../../../../selectors/toc';
import medialinkType from '../../../../../enums/medialinktype';
import Markdown from '../../../../../components/markdown';
import { formatDate } from '../../../../../utils/intl';
import TocNodeTitle from '../../sidebar/drawer/tocnode-title';
import { getProvisionalScorePercentage } from '../../../../../utils/scores';
import getGeneralConditionsLink from '../../../../../utils/getGeneralConditionsLink';
import { EMPTY_OBJECT } from '../../../../../utils/stable-default-props';

const InfoContent = ({ medialink, aside = false, downloadLink, superModuleId }) => {
  const { moduleId, hierarchy } = medialink;

  const teacherFeaturesEnabled = useSelector(state => getTeacherFeaturesEnabledFor(state));
  const module = useSelector(state => getModuleById(state, moduleId));
  const superModule = useSelector(state => getModuleById(state, superModuleId) || EMPTY_OBJECT);
  const tocNode = useSelector(state => getTOCNodeById(state)(hierarchy[hierarchy.length - 1]));

  const [t] = useTranslation();
  const isShared = (medialink.shares || []).length > 0 || (medialink.assignments || []).length > 0;
  const downloadIsPreventedWithDemoProductLicense =
    module.licenses.hasDemoProductLicenseForTeacher && !(module.licenses.hasDocumentLicenseForTeacher || module.licenses.hasDocumentLicenseForStudent);

  function formatExerciseCategories() {
    return medialink.exerciseCategories
      .map(category => category.name)
      .sort()
      .join(', ');
  }

  function formatSharerNames() {
    const formatName = ({ user }) => `${user.firstName.charAt(0)}. ${user.lastName}`;
    return [...medialink.shares, ...medialink.assignments].map(formatName).join(', ');
  }

  function renderTeacherSharedIndicator() {
    return (
      <>
        <tr className="pbb-modal__specs-spacer" />
        <tr>
          <td className="pbb-modal__specs-title">{t('sharing.shared.label')}</td>
          <td className="pbb-modal__specs-info">{isShared ? t('sharing.shared.yes') : t('sharing.shared.no')}</td>
        </tr>
      </>
    );
  }

  function renderStudentSharedIndicator() {
    return (
      <>
        <tr className="pbb-modal__specs-spacer" />
        <tr>
          <td className="pbb-modal__specs-title">{t('sharing.shared_by')}</td>
          <td className="pbb-modal__specs-info">{formatSharerNames()}</td>
        </tr>
      </>
    );
  }

  const scorePercentage = useMemo(() => {
    if (!(medialink.eduhintExercise?.showScore || medialink.edumaticExercise?.showScore) || !medialink.score) return undefined;

    return getProvisionalScorePercentage(medialink);
  }, [medialink]);

  return (
    <div data-testid="wrapper" className="pbb-modal__info-wrapper pbb-modal__info-wrapper--no-edit">
      {!aside && (
        <div className="pbb-modal__type">
          <div className="pbb-modal__type-icon">
            <MaterialIcon materialType="medialink" contentType={medialink.contentType} />
          </div>
        </div>
      )}
      <div className="pbb-modal__info">
        <div className="pbb-modal__title-wrapper">
          <div className="pbb-modal__title pbb-modal__title--text-wrap">{aside ? `${t('options.mediaOptions')} | ${t('options.info')}` : medialink.name}</div>
        </div>
        <table className="pbb-modal__specs">
          <tbody>
            <tr>
              <td className="pbb-modal__specs-title">{t('options.lastUpdated')}</td>
              <td data-testid="updated-at" className="pbb-modal__specs-info">
                {formatDate(new Date(medialink.updatedAt))}
              </td>
            </tr>
            <tr>
              <td className="pbb-modal__specs-title">{t('options.tocLevel')}</td>
              <td className="pbb-modal__specs-info">
                <TocNodeTitle tocNode={tocNode} />
              </td>
            </tr>
            <tr className="pbb-modal__specs-spacer" />

            <tr>
              <td className="pbb-modal__specs-title">{t('options.targetAudience')}</td>
              <td className="pbb-modal__specs-info">{medialink.group === 'teacher' ? t('options.teacher') : t('options.student')}</td>
            </tr>
            {teacherFeaturesEnabled ? renderTeacherSharedIndicator() : isShared && renderStudentSharedIndicator()}
            <tr className="pbb-modal__specs-spacer" />

            {medialink.contentType && (
              <tr data-testid="category">
                <td className="pbb-modal__specs-title">{t('options.category')}</td>
                <td className="pbb-modal__specs-info">
                  {medialink.contentType.category ? `${medialink.contentType.category} - ${medialink.contentType.name}` : medialink.contentType.name}
                </td>
              </tr>
            )}
            {medialink.file && (
              <tr data-testid="file-type">
                <td className="pbb-modal__specs-title">{t('options.fileType')}</td>
                <td className="pbb-modal__specs-info">{t(`fileTypeDescriptions.${getFileType(medialink.file.s3file.mimeType)}`)}</td>
              </tr>
            )}
            {medialink.externalMedia && (
              <tr data-testid="external-media-type">
                <td className="pbb-modal__specs-title">{t('options.fileType')}</td>
                <td className="pbb-modal__specs-info">{t(`externalMediaTypeDescriptions.${getExternalMediaType(medialink.externalMedia.type)}`)}</td>
              </tr>
            )}
            <tr className="pbb-modal__specs-spacer" />

            {medialink.file && medialink.file.description && (
              <tr data-testid="file-desc">
                <td className="pbb-modal__specs-title">{t('options.moreInfo')}</td>
                <td className="pbb-modal__specs-info">
                  <Markdown content={medialink.file.description} />
                </td>
              </tr>
            )}
            <tr className="pbb-modal__specs-spacer" />

            {superModule.hasExerciseEntrance && medialink.exerciseCategories && medialink.exerciseCategories.length > 0 && (
              <tr data-testid="exercise-categories">
                <td className="pbb-modal__specs-title">{t('options.exerciseCategories')}</td>
                <td className="pbb-modal__specs-info">{formatExerciseCategories()}</td>
              </tr>
            )}
            {medialink.amountOfItems && (
              <tr data-testid="amount-of-items">
                <td className="pbb-modal__specs-title">{t('options.amountOfItems')}</td>
                <td className="pbb-modal__spec-info">{medialink.amountOfItems}</td>
              </tr>
            )}
            {[medialinkType.EXERCISE_EDUHINT, medialinkType.EXERCISE_EDUMATIC].includes(medialink.kind) && (
              <>
                <tr data-testid="score">
                  <td className="pbb-modal__specs-title">{t('options.scoreResult')}</td>
                  <td className="pbb-modal__specs-info">
                    {medialink.score ? (
                      <ProgressionBar achievable={medialink.score.achievable} achieved={medialink.score.achieved} maximum={medialink.score.maximum} />
                    ) : (
                      t('options.noExercises')
                    )}
                  </td>
                </tr>
                {scorePercentage && (
                  <tr data-testid="score-percentage">
                    <td className="pbb-modal__specs-title">{t('options.scorePercentage')}</td>
                    <td className="pbb-modal__specs-info">{scorePercentage}</td>
                  </tr>
                )}
              </>
            )}
            <tr className="pbb-modal__specs-spacer" />
          </tbody>
        </table>
        {aside && (
          <div className="pbb-modal__action" data-testid="action-container">
            {downloadLink &&
              (downloadIsPreventedWithDemoProductLicense ? (
                <div className="noDownload-message" dangerouslySetInnerHTML={{ __html: t('mediaDialog.hasDemoLicense.message') }} />
              ) : (
                <a href={downloadLink} target="_blank" rel="noopener noreferrer" className="pbb-btn pbb-btn--icon-left" data-testid="download-button">
                  <i className="icon-bb-modal-download" />
                  {t('options.download')}
                </a>
              ))}

            {!downloadLink && (
              <div
                className="noDownload-message"
                dangerouslySetInnerHTML={{ __html: t('mediaDialog.noMediaDownloadModal.message', { conditionsURL: getGeneralConditionsLink() }) }}
                data-testid="no-download-message"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

InfoContent.propTypes = {
  // Own Props
  medialink: shape({
    amountOfItems: number,
    moduleId: string.isRequired,
    exerciseCategories: arrayOf(
      shape({
        name: string.isRequired,
      }),
    ),
    icon: string,
    name: string.isRequired,
    updatedAt: string.isRequired,
    hierarchy: arrayOf(string),
    group: string.isRequired,
    contentType: shape({
      category: string,
      name: string,
    }),
    file: shape({
      s3file: shape({
        mimeType: string.isRequired,
      }),
      description: string,
    }),
    kind: string.isRequired,
    score: shape({
      achievable: number.isRequired,
      achieved: number.isRequired,
      maximum: number.isRequired,
    }),
    shares: arrayOf(
      shape({
        id: string.isRequired,
        label: string,
        user: shape({
          id: string.isRequired,
          firstName: string.isRequired,
          lastName: string.isRequired,
        }),
      }),
    ),
    assignments: arrayOf(
      shape({
        id: string,
        from: string.isRequired,
        to: string.isRequired,
        repeatable: bool.isRequired,
        user: shape({
          id: string,
          firstName: string,
          lastName: string,
        }),
      }),
    ),
    edumaticExercise: shape({
      showScore: bool.isRequired,
    }),
    eduhintExercise: shape({
      showScore: bool.isRequired,
    }),
  }).isRequired,
  aside: bool,
  downloadLink: string,
  superModuleId: string.isRequired,
};

export default InfoContent;
