import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, string, arrayOf, bool } from 'prop-types';
import ArrowLeftIcon from '@pelckmans/business-components/icons/ArrowLeft';
import CategoryFilter from './MaterialFilterSettingsCategoryFilter';
import { EMPTY_ARRAY } from '../../../../../../utils/stable-default-props';

export default function MaterialFilterSettings({ medialinkCategories = EMPTY_ARRAY, close, teacherFeaturesEnabled = false, moduleId, materialCategoriesToHide = EMPTY_ARRAY }) {
  const [t] = useTranslation();

  // add own material
  const categories = [...medialinkCategories, t(teacherFeaturesEnabled ? 'drawer.material.sharedMedia' : 'drawer.material.sharedByTeacher').toUpperCase()];
  return (
    <>
      <span data-testid="material-title" className="pbb-drawer__intro">
        {t('drawer.material.filterSettings.title')}
      </span>
      <div className="pbb-material pbb-material--settings pbb-drawer__contentwrap">
        <div className="pbb-material__wrapper pbb-drawer__content">
          <p>{t('drawer.material.filterSettings.description')}</p>
          <ul className="pbb-material__settings">
            {categories.map(category => {
              const key = category || t('drawer.material.withoutCategory');
              return <CategoryFilter category={key} moduleId={moduleId} key={key} materialCategoriesToHide={materialCategoriesToHide} />;
            })}
          </ul>
        </div>
      </div>
      <div className="pbb-footer pbb-footer-material--settings">
        <div className="pbb-footer__wrapper">
          <button className="pbb-btn pbb-btn--inverted pbb-btn--display-block pbb-btn--icon-left" type="button" onClick={close}>
            <ArrowLeftIcon />
            <span>{t('drawer.material.filterSettings.footer.back')}</span>
          </button>
        </div>
      </div>
    </>
  );
}

MaterialFilterSettings.propTypes = {
  close: func.isRequired,
  medialinkCategories: arrayOf(string),
  teacherFeaturesEnabled: bool,
  moduleId: string.isRequired,
  materialCategoriesToHide: arrayOf(string),
};
