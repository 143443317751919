import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { oneOf, string } from 'prop-types';
import GenericModal from '../common/GenericModal';
import dialogTypes from '../../../../../enums/dialogTypes';
import { closeDialog } from '../../../../../actions/dialog';
import { deleteWhitepage } from '../../../../../actions/whitepages';
import Tabs from '../tabs/tabs';
import Tab from '../tabs/tab';
import Info from './info';
import { getTOCNodeById } from '../../../../../selectors/toc';
import Alert from '../../../../../components/alert';
import TranslationButton from '../../../../../components/translation-button';
import { getWhitepageById } from '../../../../../selectors/whitepages';

const tabs = {
  INFO: 'info',
  DELETE: 'delete',
};

const WhitepageOptionsModal = ({ whitepageId, tocNodeId, activeTab }) => {
  const dispatch = useDispatch();
  const tocNode = useSelector(state => getTOCNodeById(state)(tocNodeId));
  const whitepage = useSelector(state => getWhitepageById(state, whitepageId));

  const [t] = useTranslation();

  const [currentTab, setCurrentTab] = useState(tabs.INFO);

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  const handleClose = useCallback(() => dispatch(closeDialog(whitepage.id, dialogTypes.WHITEPAGE_OPTIONS)), [dispatch, whitepage.id]);

  const handleDelete = useCallback(() => {
    dispatch(deleteWhitepage(whitepage.id, tocNode.id));
    handleClose();
  }, [whitepage.id, tocNode.id, dispatch, handleClose]);

  const footer = useMemo(() => {
    switch (currentTab) {
      case tabs.DELETE:
        return (
          <>
            <TranslationButton onClick={handleClose} label="whitepages.modal.buttons.cancel" className="pbb-btn--inverted" data-testid="cancel-btn" />
            <TranslationButton onClick={handleDelete} label="whitepages.modal.buttons.delete" className="pbb-btn--danger" data-testid="delete-btn" />
          </>
        );
      default:
      case tabs.INFO:
        return <TranslationButton onClick={handleClose} label="whitepages.modal.buttons.close" className="pbb-btn--inverted" data-testid="close-btn" />;
    }
  }, [handleClose, handleDelete, currentTab]);

  const tabBar = useMemo(
    () => (
      <Tabs onClick={setCurrentTab} activeTab={currentTab}>
        {whitepage.id && <Tab title={t('tabs.info.title')} id="info" icon="icon-info" />}
        {whitepage.id && <Tab title={t('tabs.delete')} id="delete" icon="icon-trash" />}
      </Tabs>
    ),
    [currentTab, t, whitepage],
  );

  const tabContent = useMemo(() => {
    switch (currentTab) {
      case tabs.DELETE:
        return <Alert message={t('whitepages.modal.alerts.delete')} />;
      default:
      case tabs.INFO:
        return <Info whitepage={whitepage} tocNode={tocNode} />;
    }
  }, [currentTab, t, whitepage, tocNode]);

  return (
    <GenericModal
      icon={`icon-bb-whitepage-${whitepage.paperType}`}
      close={handleClose}
      title={whitepage.title}
      footerContent={footer}
      tabBar={tabBar}
      contentModifier="pbb-modal-whitepages pbb-modal--fixed-height "
    >
      {tabContent}
    </GenericModal>
  );
};

WhitepageOptionsModal.propTypes = {
  whitepageId: string.isRequired,
  tocNodeId: string.isRequired,
  activeTab: oneOf([tabs.INFO, tabs.DELETE]),
};

export default WhitepageOptionsModal;
