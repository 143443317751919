import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { shape, string, arrayOf, bool } from 'prop-types';
import BookSwitcher from './bookSwitcher/bookSwitcher';
import { getRelatedDigibookAppsForCurrentDigibook } from '../../../../../../selectors/digibooks';
import { EMPTY_ARRAY } from '../../../../../../utils/stable-default-props';

export const Module = ({ module, relatedDigibooks = EMPTY_ARRAY, superModule, digibook }) => {
  const [showBookSwitcher, setShowBookSwitcher] = React.useState(false);
  const [t] = useTranslation();
  const anyOtherRelatedDigibook = relatedDigibooks.length > 0 && relatedDigibooks.some(book => book.digibookId !== digibook.id);

  return (
    <div className="pbb-module">
      {superModule && <div className="pbb-module__title">{superModule.name}</div>}
      <div className="pbb-submodule__title">{module.niceName}</div>
      {superModule && (
        <>
          {superModule.styling && (
            <style>
              {`.pbb-module, .pbb-submodule__dropdown { border-bottom-color: ${superModule.styling.primaryColor}}`}
              {`.pbb-submodule__selector:before { background: ${superModule.styling.primaryColor} }`}
            </style>
          )}
          {anyOtherRelatedDigibook && (
            <>
              <button
                className={classNames('pbb-submodule__selector pbb-hamburger pbb-hamburger--htx', {
                  'is-active': showBookSwitcher,
                })}
                type="button"
                onClick={() => setShowBookSwitcher(!showBookSwitcher)}
                title={!showBookSwitcher ? t('drawer.toc.switcher.buttons.tooltip') : ''}
              >
                <span>toggle menu</span>
              </button>
              <BookSwitcher open={showBookSwitcher} digibookApps={relatedDigibooks} superModuleId={superModule.id} currentDigibookId={digibook.id} />
            </>
          )}
        </>
      )}
    </div>
  );
};

Module.propTypes = {
  module: shape({
    id: string,
    niceName: string,
  }).isRequired,
  relatedDigibooks: arrayOf(
    shape({
      id: string,
      name: string,
      bookType: string,
      niceName: string,
      cover: string,
      hasLicence: bool,
    }),
  ),
  superModule: shape({
    styling: shape({
      primaryColor: string,
    }),
    id: string.isRequired,
    name: string.isRequired,
  }),
  digibook: shape({
    id: string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  relatedDigibooks: ownProps.superModule ? getRelatedDigibookAppsForCurrentDigibook(state) : [],
});

export default connect(mapStateToProps)(Module);
