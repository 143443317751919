import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@pelckmans/business-components/components/button';
import { func, number, string, shape, oneOfType, arrayOf, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import colorNames, { colorObjects } from '../../enums/colors';
import ColorWheel from './color-wheel';
import { rgbToRgba } from '../../utils/colors';

const emptyDefaultColor = 'rgba(214,214,214,1)';

const ColorPicker = ({ activeColor, onColorSelect, customColors, saveCustomColor, isOpaque, circled }) => {
  const { t } = useTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedCustomColorIndex, setSelectedCustomColorIndex] = useState(Object.values(colorNames).includes(activeColor?.id) ? undefined : activeColor?.order);

  const rendered = useRef(false);
  useEffect(() => {
    if (rendered.current) {
      setSelectedCustomColorIndex(customColors.find(color => color && color.id === activeColor?.id)?.order);
    }

    rendered.current = true;
  }, [activeColor?.id, customColors]);

  const handleConfirm = async color => {
    const updatedColor = await saveCustomColor({ id: customColors[selectedCustomColorIndex]?.id, order: selectedCustomColorIndex, color });
    onColorSelect(updatedColor);
    setShowColorPicker(false);
  };

  const handleDefaultColorClick = color => {
    onColorSelect(color);
    setSelectedCustomColorIndex(undefined);
  };

  return (
    <div className="advanced-color-picker-container">
      <div className="advanced-color-picker">
        <div className="colors">
          {Object.values(colorObjects).map(defaultColor => {
            const { id, color } = defaultColor;

            const indicatorColor = isOpaque ? rgbToRgba(color, 0.75) : color;

            const isActive = id === activeColor?.id;
            return (
              <button
                title={t(`colors.${id}`)}
                onClick={() => handleDefaultColorClick(defaultColor)}
                key={`default-color-${id}`}
                className={classNames('color', { 'active-button': isActive })}
                data-testid={`default-color-${id}`}
                type="button"
              >
                <span
                  data-testid="indicator"
                  className={classNames('indicator', { active: isActive, 'indicator--circled': circled })}
                  style={{ backgroundColor: indicatorColor, outline: isActive ? `2px solid ${indicatorColor}` : 'none' }}
                />
              </button>
            );
          })}
        </div>
        <div className="divider-line" />
        <div className="colors">
          {customColors.map((customColor, index) => {
            const isActive = index === selectedCustomColorIndex || (customColor && customColor.id === activeColor?.id);
            const indicatorColor = isOpaque && customColor ? rgbToRgba(customColor.color, 0.75) : customColor?.color || emptyDefaultColor;

            return (
              <button
                onClick={() => {
                  setSelectedCustomColorIndex(index);
                  if (customColor) onColorSelect(customColor);
                }}
                className={classNames('color', { 'active-button': isActive })}
                // array is sealed
                // eslint-disable-next-line react/no-array-index-key
                key={`custom-color-${index}`}
                type="button"
                title={t('colorPicker.customSlot.title')}
                data-testid={`custom-color-${index}`}
              >
                <span
                  className={classNames('indicator', { active: isActive, 'indicator--circled': circled })}
                  style={{ backgroundColor: indicatorColor, outline: isActive ? `2px solid ${indicatorColor}` : 'none' }}
                >
                  {!customColor && '+'}
                </span>
              </button>
            );
          })}
        </div>
        {!showColorPicker && selectedCustomColorIndex !== undefined && (
          <Button size="small" onClick={() => setShowColorPicker(true)} className="button__edit">
            {t('colorPicker.buttons.edit')}
          </Button>
        )}
        {showColorPicker && <ColorWheel onClose={() => setShowColorPicker(false)} onConfirm={handleConfirm} activeColor={activeColor?.color || colorObjects.black.color} />}
      </div>
    </div>
  );
};

const customColorShape = shape({
  id: oneOfType([string, number]).isRequired,
  color: string.isRequired,
  order: number.isRequired,
});

ColorPicker.propTypes = {
  activeColor: customColorShape,
  customColors: arrayOf(customColorShape).isRequired,
  onColorSelect: func.isRequired,
  saveCustomColor: func.isRequired,
  isOpaque: bool,
  circled: bool,
};

export default ColorPicker;
