import React, { useCallback, useMemo } from 'react';
import { ExerciseManager } from '@pelckmans/business-components/components/exercise-manager';
import { ExerciseDialogsContext } from '@pelckmans/business-components/context/ExerciseDialogsContext';
import { shape, bool, string, arrayOf } from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import get from 'get-value';

import { tokenGetter } from '../../../../services/api';
import { closeDialog } from '../../../../actions/dialog';
import { errorOccurred } from '../../../../actions/error';
import { setMediaLinkExerciseSessionsResult } from '../../../../actions/medialinks';
import { LOCATION_ID } from '../../../../api/analyticsApi';
import { STUDIO_GROUP } from '../../../../constants/constants';

const ExerciseFlowContainer = ({ medialink, superModuleId, assignmentId, subLocationId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();

  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  const translations = useMemo(
    () => ({
      closeDialog: t('businessComponents.exerciseManager.closeDialog'),
      loading: t('businessComponents.exerciseManager.loading'),
      generalNode: t('businessComponents.exerciseManager.generalNode'),
      exerciseDialog: {
        loading: t('businessComponents.exerciseManager.exerciseDialog.loading'),
        calculating: t('businessComponents.exerciseManager.exerciseDialog.calculating'),
        buttons: {
          pause: t('businessComponents.exerciseManager.exerciseDialog.buttons.pause'),
        },
      },
      exerciseRestartDialog: {
        title: t('businessComponents.exerciseManager.exerciseRestartDialog.title'),
        message: {
          p1: {
            restartable: t('businessComponents.exerciseManager.exerciseRestartDialog.message.p1.restartable'),
            nonRestartable: t('businessComponents.exerciseManager.exerciseRestartDialog.message.p1.nonRestartable'),
          },
        },
        scorePrefix: t('businessComponents.exerciseManager.exerciseRestartDialog.scorePrefix'),
        result: t('businessComponents.exerciseManager.exerciseRestartDialog.result'),
        buttons: {
          restart: t('businessComponents.exerciseManager.exerciseRestartDialog.buttons.restart'),
          cancel: t('businessComponents.exerciseManager.exerciseRestartDialog.buttons.cancel'),
          review: t('businessComponents.exerciseManager.exerciseRestartDialog.buttons.review'),
        },
      },
      exerciseResumeDialog: {
        title: t('businessComponents.exerciseManager.exerciseResumeDialog.title'),
        message: {
          p1: {
            restartable: t('businessComponents.exerciseManager.exerciseResumeDialog.message.p1.restartable'),
            nonRestartable: t('businessComponents.exerciseManager.exerciseResumeDialog.message.p1.nonRestartable'),
          },
          p2: t('businessComponents.exerciseManager.exerciseResumeDialog.message.p2'),
        },
        scorePrefix: t('businessComponents.exerciseManager.exerciseResumeDialog.scorePrefix'),
        result: t('businessComponents.exerciseManager.exerciseResumeDialog.result'),
        buttons: {
          resume: t('businessComponents.exerciseManager.exerciseResumeDialog.buttons.resume'),
          restart: t('businessComponents.exerciseManager.exerciseResumeDialog.buttons.restart'),
          cancel: t('businessComponents.exerciseManager.exerciseResumeDialog.buttons.cancel'),
        },
      },
      exerciseResultState: {
        title: t('businessComponents.exerciseManager.exerciseResultState.title'),
        buttons: {
          restart: t('businessComponents.exerciseManager.exerciseResultState.buttons.restart'),
          close: t('businessComponents.exerciseManager.exerciseResultState.buttons.close'),
          review: t('businessComponents.exerciseManager.exerciseResultState.buttons.review'),
        },
        messages: {
          veryGood: t('businessComponents.exerciseManager.exerciseResultState.messages.veryGood'),
          wellDone: t('businessComponents.exerciseManager.exerciseResultState.messages.wellDone'),
          bad: t('businessComponents.exerciseManager.exerciseResultState.messages.bad'),
          showScore: {
            result: t('businessComponents.exerciseManager.exerciseResultState.messages.showScore.result'),
            perfectResult: t('businessComponents.exerciseManager.exerciseResultState.messages.showScore.perfectResult'),
          },
        },
      },
      assignmentExpiredDialog: {
        title: t('businessComponents.exerciseManager.assignmentExpiredDialog.title'),
        message: t('businessComponents.exerciseManager.assignmentExpiredDialog.message'),
        buttons: {
          close: t('businessComponents.exerciseManager.assignmentExpiredDialog.buttons.close'),
        },
      },
      exercisePauseDialog: {
        title: t('businessComponents.exerciseManager.exercisePauseDialog.title'),
        message: {
          p1: t('businessComponents.exerciseManager.exercisePauseDialog.message.p1'),
          p2: t('businessComponents.exerciseManager.exercisePauseDialog.message.p2'),
        },
        buttons: {
          pause: t('businessComponents.exerciseManager.exercisePauseDialog.buttons.pause'),
          continue: t('businessComponents.exerciseManager.exercisePauseDialog.buttons.continue'),
        },
      },
      exercisePausedState: {
        title: t('businessComponents.exerciseManager.exercisePausedState.title'),
        buttons: {
          close: t('businessComponents.exerciseManager.exercisePausedState.buttons.close'),
        },
        messages: {
          incomplete: t('businessComponents.exerciseManager.exercisePausedState.messages.incomplete'),
          veryGood: t('businessComponents.exerciseManager.exercisePausedState.messages.veryGood'),
          wellDone: t('businessComponents.exerciseManager.exercisePausedState.messages.wellDone'),
          bad: t('businessComponents.exerciseManager.exercisePausedState.messages.bad'),
        },
        showScore: {
          title: t('businessComponents.exerciseManager.exercisePausedState.showScore.title'),
        },
      },
      exerciseFailedState: {
        message: t('businessComponents.exerciseManager.exerciseFailedState.message'),
        buttons: {
          close: t('businessComponents.exerciseManager.exerciseFailedState.buttons.close'),
          retry: t('businessComponents.exerciseManager.exerciseFailedState.buttons.retry'),
        },
      },
      serviceNotAvailable: {
        title: t('businessComponents.exerciseManager.serviceNotAvailable.title'),
        p1: t('businessComponents.exerciseManager.serviceNotAvailable.p1'),
        p2: t('businessComponents.exerciseManager.serviceNotAvailable.p2'),
      },
    }),
    [t],
  );

  const onClose = () => dispatch(closeDialog(medialink.id));

  const onError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in exercise manager component' })), [dispatch]);

  const handleSessionPaused = useCallback(exerciseSessionResult => dispatch(setMediaLinkExerciseSessionsResult(medialink.id, exerciseSessionResult)), [dispatch, medialink.id]);

  const handleSessionFinished = useCallback(exerciseSessionResult => dispatch(setMediaLinkExerciseSessionsResult(medialink.id, exerciseSessionResult)), [dispatch, medialink.id]);

  const superModule = useSelector(state => get(state, ['module', 'byId', superModuleId]));

  return createPortal(
    <ExerciseDialogsContext.Provider value={{ header: { iconClassName: 'bc-icon bc-icon-exercise' } }}>
      <ExerciseManager
        translations={translations}
        language={language}
        medialink={medialink}
        assignmentId={assignmentId}
        superModule={superModule}
        tokenAccessor={tokenAccessor}
        onClose={onClose}
        onError={onError}
        onSessionPaused={handleSessionPaused}
        onSessionFinished={handleSessionFinished}
        analyticsData={{
          locationId: LOCATION_ID,
          subLocationId,
        }}
        studioGroup={STUDIO_GROUP}
      />
    </ExerciseDialogsContext.Provider>,
    document.body,
  );
};

ExerciseFlowContainer.propTypes = {
  medialink: shape({
    id: string.isRequired,
    moduleId: string.isRequired,
    name: string.isRequired,
    hierarchy: arrayOf(
      shape({
        id: string.isRequired,
        displayPrefix: bool.isRequired,
        prefix: string,
        title: string.isRequired,
      }),
    ).isRequired,
    contentType: shape({
      name: string.isRequired,
      icon: string,
      iconClassName: string,
      faqTutorial: string,
    }),
    exerciseCategories: arrayOf(
      shape({
        name: string.isRequired,
      }),
    ),
    kind: string.isRequired,
  }).isRequired,
  assignmentId: string,
  superModuleId: string,
  subLocationId: string.isRequired,
};

export default ExerciseFlowContainer;
