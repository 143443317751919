import CloseInCircleIcon from '@pelckmans/business-components/icons/CloseInCircle';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';

function renderToString(elem) {
  const div = document.createElement('div');

  flushSync(() => {
    createRoot(div).render(elem);
  });

  return div.innerHTML;
}

export const whitePageHeaderCloseIcon = renderToString(<CloseInCircleIcon width="80" height="80" fill="#004d62" />);
