import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import AuthCallback from './components/auth/auth-callback';
import PrivateRoute from './components/private-route';
import GlobalErrorBoundary from './components/global-error-boundary';
import ErrorComponent from './components/error';

import Player from './modules/player';
import Severity from './enums/severity';
import BackToPButton from './components/back-to-p-button';
import Maintenance from './components/maintenance';
import { PORTAAL_API_URL, STUDIO_GROUP } from './constants/constants';

export function Routes() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const preventDblClick = e => e.preventDefault();

    document.addEventListener('dblclick', preventDblClick);

    return () => document.removeEventListener('dblclick', preventDblClick);
  });

  return (
    <GlobalErrorBoundary>
      <Switch>
        <Route path="/oauth2-redirect">
          <AuthCallback />
        </Route>
        <Route path="/maintenance">
          <Maintenance title={t('errors.maintenance.title')} message={t(`errors.maintenance.message`)} />
        </Route>
        <Route
          path="/sso/:provider(pelckmans|zogezegd|zogezegd-leerkracht|digiportail|smartschool)/:id([0-9a-fA-F]{24})/:page?"
          render={({ match: { params }, location: { search } }) => {
            const { provider, id, page } = params;

            localStorage.setItem('p-provider', provider);

            const returnUrl = `/digibook/${id}${page ? `/${page}` : ''}${search}`;

            const { protocol, host, origin } = window.location;

            const query = new URLSearchParams({
              provider,
              language,
              redirect_uri: `${protocol}//${host}/oauth2-redirect`,
              returnUrl,
              register_return_url: origin,
              studioGroup: STUDIO_GROUP,
            });

            window.location.replace(`${PORTAAL_API_URL}/api/oauth/authorize?${query}`);

            return null;
          }}
        />
        <PrivateRoute
          path="/digibook/:id([0-9a-fA-F]{24})/:page?"
          render={({ match, location }) => {
            const { superModuleId, openDrawer, openWhitepage } = qs.parse(location.search);
            return (
              <Player
                digibookId={match.params.id}
                initialPage={Number(match.params.page) || undefined}
                superModuleId={superModuleId}
                initialOpenDrawer={openDrawer}
                initialOpenWhitepageId={openWhitepage}
              />
            );
          }}
        />
        <Route>
          <ErrorComponent title={t('pageNotFound')} severity={Severity.WARNING}>
            <BackToPButton />
          </ErrorComponent>
        </Route>
      </Switch>
    </GlobalErrorBoundary>
  );
}

export default Routes;
