import { getCurrentModuleId } from '../selectors/digibooks';
import { getNoteIdsGroupedByTocNodeId } from '../selectors/notes';
import { getVisibleChapterNodeIds } from '../selectors/toc';
import api from '../services/api';
import { NOTES_DELETE_NOTE_SUCCESS, NOTES_FETCH_BY_NODEID_SUCCESS, NOTES_SAVE_NOTE_SUCCESS } from './actionNames';
import { openPopUpNote } from './dialog';

const saveNoteSuccess = note => ({
  type: NOTES_SAVE_NOTE_SUCCESS,
  payload: { note },
});

export function createNote(nodeId, content = ' ') {
  return async (dispatch, getState) => {
    const state = getState();
    const moduleId = getCurrentModuleId(state);
    const { data } = await api.post(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/notes`, {
      note: content,
    });

    dispatch(saveNoteSuccess(data));
    return dispatch(openPopUpNote(data.nodeId, data.id));
  };
}

export function updateNote(note) {
  return async (dispatch, getState) => {
    const state = getState();
    const moduleId = getCurrentModuleId(state);
    const { data } = await api.put(`/studio/user/modules/${moduleId}/table-of-content/${note.nodeId}/notes/${note.id}`, {
      note: note.note,
    });

    return dispatch(saveNoteSuccess(data));
  };
}

export function retrieveNotesByNodeId(nodeId) {
  return async (dispatch, getState) => {
    const state = getState();
    const moduleId = getCurrentModuleId(state);
    const response = await api.get(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/notes`);

    return dispatch({
      type: NOTES_FETCH_BY_NODEID_SUCCESS,
      payload: {
        nodeId,
        notes: response.data.data,
      },
    });
  };
}

export function retrieveNotesForCurrentPages() {
  return (dispatch, getState) => {
    const state = getState();
    const chapterIds = getVisibleChapterNodeIds(state) || [];
    const promises = chapterIds.map(nodeId => (getNoteIdsGroupedByTocNodeId(state, nodeId) ? Promise.resolve() : dispatch(retrieveNotesByNodeId(nodeId))));

    return Promise.all(promises);
  };
}

const deleteNoteSuccess = ({ noteId, nodeId }) => ({
  type: NOTES_DELETE_NOTE_SUCCESS,
  payload: {
    noteId,
    nodeId,
  },
});

export const deleteNote = ({ noteId, nodeId }) => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);

  await api.delete(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/notes/${noteId}`);

  dispatch(deleteNoteSuccess({ noteId, nodeId }));
};
