import get from 'get-value';
import ENTITY_TYPE from '../enums/entityType';
import { getMedialink } from './medialinks';

export const getOpenDialogs = state => get(state, ['dialogs', 'openDialogs']);

export const getOpenMinisite = state => {
  const id = get(state, ['dialogs', 'openMinisite', 'current']);

  return get(state, ['dialogs', 'openMinisite', id]);
};

export const getMedialinksChooserData = state => {
  const linkAreaId = get(state, ['dialogs', 'medialinksChooser', 'current']);

  const data = get(state, ['dialogs', 'medialinksChooser', linkAreaId]);
  if (data && data.medialinkIds) {
    const { medialinkIds, ...rest } = data;
    const res = { ...rest, medialinks: medialinkIds.map(id => getMedialink(state, id)) };
    return res;
  }
  return data;
};

export const getOpenMaterialAssignment = state => get(state, ['dialogs', 'openMaterialAssignment']);

export const getPopUpNoteCount = state => state.dialogs.openDialogs.filter(dialog => dialog.entityType === ENTITY_TYPE.POP_UP_NOTE).length;
