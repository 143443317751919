import { ActionButton, ActionMenu, MenuItem } from '@pelckmans/business-components/components/action-menu';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import { bool, string } from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addWhitepage } from '../../../../../../../actions/whitepages';
import PaperType from '../../../../../../../enums/paperType';
import { getCurrentDigibook } from '../../../../../../../selectors/digibooks';
import { isTeacherAlike } from '../../../../../../../selectors/user';
import NotLicensedModal from '../../../../dialogs/NotLicensedModal';

function AddWhitepageMenu({ tocNodeId, showOnlyFavoriteWhitepages }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [notLicensedVisible, setNotLicensedVisible] = useState(false);

  const createWhitepage = (nodeId, paperType, asFavorite) => dispatch(addWhitepage(nodeId, paperType, asFavorite));

  const isTeacher = useSelector(isTeacherAlike);

  const digibook = useSelector(getCurrentDigibook);

  return (
    <>
      {notLicensedVisible &&
        createPortal(
          <NotLicensedModal title={t('notLicensed.accessFeature.title')} message={t('notLicensed.accessFeature.message')} onClose={() => setNotLicensedVisible(false)} />,
          document.body,
        )}
      {isTeacher && !digibook.teacherDigitalManualLicense && (
        <li className="pbb-list__item">
          <span className="pbb-list__link-wrap">
            <button type="button" onClick={() => setNotLicensedVisible(true)} className="pbb-list__link">
              <PlusInCircleIcon className="pbb-list__link-icon" />
              <div className="pbb-list__link-text">{t('drawer.whitepages.buttons.add')}</div>
            </button>
          </span>
        </li>
      )}
      {digibook.teacherDigitalManualLicense && (
        <li className="pbb-list__item" title={t('drawer.whitepages.buttons.add_tooltip')}>
          <ActionMenu
            direction="bottom"
            menuStyle={{ width: 340 - 2 * 16 }} // drawer width minus padding
            action={
              <span className="pbb-list__link-wrap">
                <ActionButton className="pbb-list__link">
                  <PlusInCircleIcon className="pbb-list__link-icon" />
                  <div className="pbb-list__link-text">{t('drawer.whitepages.buttons.add')}</div>
                </ActionButton>
              </span>
            }
          >
            {Object.values(PaperType).map(type => (
              <MenuItem key={`icon-bb-whitepage-${type}`} onClick={() => createWhitepage(tocNodeId, type, showOnlyFavoriteWhitepages)} data-testid={`add-whitepage-${type}`}>
                <MaterialIcon materialType="whitepage" paperType={type} className="pbb-list__link-icon" />
                <span className="pbb-list__link-text">{t(`drawer.whitepages.types.${type}`)}</span>
              </MenuItem>
            ))}
          </ActionMenu>
        </li>
      )}
    </>
  );
}

AddWhitepageMenu.propTypes = {
  tocNodeId: string.isRequired,
  showOnlyFavoriteWhitepages: bool.isRequired,
};

export default AddWhitepageMenu;
