const OFFSET = 20;

const removePixelUnitFromStyle = cssValue => Number(cssValue.replace('px', ''));

export const getInitialPopUpPosition = isLeft => {
  const computedStyle = window.getComputedStyle(document.documentElement);

  const sidebarWidth = removePixelUnitFromStyle(computedStyle.getPropertyValue('--sidebar-width'));
  const dockHeight = removePixelUnitFromStyle(computedStyle.getPropertyValue('--dock-height'))

  const width = (window.innerWidth - sidebarWidth) / 3;
  return {
    x: isLeft ? OFFSET + sidebarWidth : width * 2 - OFFSET,
    y: OFFSET,
    width,
    height: window.innerHeight - (2 * OFFSET + dockHeight),
  };
};
