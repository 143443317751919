/* eslint-disable react/no-danger */
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import PencilWithSquareIcon from '@pelckmans/business-components/icons/PencilWithSquare';

import { arrayOf, bool, func, objectOf, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

import { openNoteOptionsModal, openPopUpNote as openPopUpNoteModal } from '../../../../../../actions/dialog';
import Alert from '../../../../../../components/alert';
import Collapsible from '../../../../../../components/collapsible';
import { getNotesForActiveNodes } from '../../../../../../selectors/notes';
import { getActiveNodes } from '../../../../../../selectors/toc';
import { formatDate } from '../../../../../../utils/intl';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../../../utils/stable-default-props';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import TocNodeTitle from '../tocnode-title';
import AddNoteButton from './components/AddNoteButton';

export function Notes({ dispatch, activeNodes = EMPTY_ARRAY, notesForActiveNodes = EMPTY_OBJECT }) {
  const [t] = useTranslation();

  const openNoteOptions = (tocNodeId, noteId, activeTab) => dispatch(openNoteOptionsModal(tocNodeId, noteId, activeTab));

  const openPopUpNote = (tocNodeId, noteId) => dispatch(openPopUpNoteModal(tocNodeId, noteId));

  return (
    <>
      <p className="pbb-drawer__intro">{t('drawer.notes.title')}</p>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content">
          <div className="pbb-notes">
            {activeNodes.length ? (
              <Collapsible>
                {activeNodes.map(tocNode => {
                  const notes = notesForActiveNodes[tocNode.id] || [];
                  const sortedNotes = [...notes].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

                  return (
                    <Collapsible.Item key={tocNode.id}>
                      <Collapsible.Title>
                        <span className="pbb-collapsible__title-text">
                          {tocNode.displayPrefix && <span className="pbb-collapsible__subtitle">{tocNode.prefix}</span>}
                          <span className="pbb-collapsible__title">
                            <TocNodeTitle tocNode={tocNode} />
                          </span>
                        </span>
                      </Collapsible.Title>
                      <Collapsible.Content>
                        <ul className="pbb-list pbb-list--borders">
                          {sortedNotes.length === 0 && (
                            <li>
                              <Alert message={t('drawer.notes.no_notes')} className="pbb-alert--spaced" />
                            </li>
                          )}
                          <li className="pbb-list__item">
                            <AddNoteButton dispatch={dispatch} nodeId={tocNode.id} />
                          </li>
                          {sortedNotes.map(note => (
                            <li className="pbb-list__item" key={note.id} data-testid="notes" data-id={note.id}>
                              <div className="pbb-list__link-wrap">
                                <button type="button" className="pbb-list__link" onClick={() => openPopUpNote(tocNode.id, note.id)}>
                                  <div className="pbb-note">
                                    <div className="pbb-note__caption">
                                      <span className="pbb-note__label">{formatDate(new Date(note.updatedAt))}</span>
                                    </div>
                                    <div className="pbb-note__body" dangerouslySetInnerHTML={{ __html: note.note }} />
                                  </div>
                                </button>
                                <div className="pbb-list__actions">
                                  <DrawerOptionsMenu>
                                    <MenuItem onClick={() => openPopUpNote(tocNode.id, note.id)}>
                                      <PencilWithSquareIcon />
                                      <span>{t('drawer.notes.actionMenu.edit')}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => openNoteOptions(tocNode.id, note.id, 'info')}>
                                      <InfoIcon />
                                      <span>{t('tabs.info.title')}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => openNoteOptions(tocNode.id, note.id, 'delete')}>
                                      <BinIcon />
                                      <span>{t('tabs.delete')}</span>
                                    </MenuItem>
                                  </DrawerOptionsMenu>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Collapsible.Content>
                    </Collapsible.Item>
                  );
                })}
              </Collapsible>
            ) : (
              <Alert message={t('drawer.notes.no_notes')} />
            )}
          </div>
        </div>
      </SimpleBar>
    </>
  );
}

Notes.propTypes = {
  dispatch: func.isRequired,
  activeNodes: arrayOf(
    shape({
      id: string,
      title: string,
      prefix: string,
      displayPrefix: bool,
    }),
  ),
  notesForActiveNodes: objectOf(
    arrayOf(
      shape({
        id: string,
        moduleId: string,
        nodeId: string,
        note: string,
        createdAt: string,
        updatedAt: string,
      }),
    ),
  ),
};

const mapStateToProps = state => ({
  activeNodes: getActiveNodes(state),
  notesForActiveNodes: getNotesForActiveNodes(state),
});

export default connect(mapStateToProps)(Notes);
