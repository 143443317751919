import get from 'get-value';
import { createSelector } from 'reselect';
import { getModuleForDigibook } from './digibooks';
import { getActiveNodesForMediaLinks } from './toc';
import { getIsSlideSetsReviewer, isTeacherAlike } from './user';

export const getSlideSetsByTocNodeId = (state, nodeId) => get(state, ['slideSets', 'byTocNodeId', nodeId]);
export const getSlideSetByVersionId = (state, id) => get(state, ['slideSets', 'byVersionId', id]);

const getSlideSetsForCurrentPages = state => {
  const visibleTOCNodes = getActiveNodesForMediaLinks(state);

  const slideSets = visibleTOCNodes.reduce((acc, node) => {
    const chapterNodeId = node.hierarchy && node.hierarchy[0] ? node.hierarchy[0] : node.id;

    const chapterNodeSlideSetVersionIds = getSlideSetsByTocNodeId(state, chapterNodeId);

    if (chapterNodeSlideSetVersionIds && chapterNodeSlideSetVersionIds.length) {
      const expandedSlideSets = chapterNodeSlideSetVersionIds.map(id => getSlideSetByVersionId(state, id));
      const slideSetsForNode = expandedSlideSets.filter(slideSet => node.id === slideSet.nodeId);
      acc.push(...slideSetsForNode);
    }
    return acc;
  }, []);

  return slideSets;
};

export const getSlideSetsForCurrentPagesGroupedByNode = state =>
  getSlideSetsForCurrentPages(state).reduce((acc, slideSet) => {
    const { nodeId } = slideSet;

    if (acc[nodeId]) {
      acc[nodeId].push(slideSet);
    } else {
      acc[nodeId] = [slideSet];
    }

    return acc;
  }, {});

export const getIsSlideSetsAccessEnabled = createSelector(
  [getModuleForDigibook, getIsSlideSetsReviewer],
  (module, isReviewer) => Boolean(module?.enableSlideSetsAccess) || isReviewer,
);

export const getIsStudentWithoutSharedSlideSets = state => {
  const isStudent = !isTeacherAlike(state);
  const hasSharedSlideSetsInModule = Object.values(state.slideSets.byTocNodeId).flat();

  return isStudent && !hasSharedSlideSetsInModule.length;
};
