import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SchoolYearProvider } from '@pelckmans/business-components/context/SchoolYearContext';

import store from './store';
import Routes from './routes';
import { configureRefreshTokenInterceptor } from './services/utils/ConfigureRefreshTokenInterceptor';
import { CustomColorProvider } from './contexts/CustomColorContext';

configureRefreshTokenInterceptor(store.dispatch);

export default function App() {
  return (
    <Provider store={store}>
      <SchoolYearProvider studioGroup={process.env.STUDIO_GROUP}>
        <CustomColorProvider>
          <Router>
            <Routes />
          </Router>
        </CustomColorProvider>
      </SchoolYearProvider>
    </Provider>
  );
}
