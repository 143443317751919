import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import ManualOnNL from '@pelckmans/business-components/icons/ManualWithMargin';
import ManualOnFR from '@pelckmans/business-components/icons/ManualWithMarginGP';
import classNames from 'classnames';

const getSource = language => (language === 'fr' ? <ManualOnFR /> : <ManualOnNL />);

export const ManualLayerButton = ({ isEnabled, onClick }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const icon = getSource(language, isEnabled);
  const tooltip = isEnabled ? 'dock.buttons.manualOff.tooltip' : 'dock.buttons.manualOn.tooltip';

  return (
    <button type="button" onClick={onClick} title={t(tooltip)} className={classNames('manual-icon', { 'manual-icon--active': isEnabled })}>
      {icon}
    </button>
  );
};

ManualLayerButton.propTypes = {
  isEnabled: bool.isRequired,
  onClick: func.isRequired,
};
