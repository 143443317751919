import React from 'react';
import marked from 'marked';
import { string } from 'prop-types';

function Markdown({ content = '' }) {
  // eslint-disable-next-line no-underscore-dangle
  const __html = marked(content);

  return (
    <div
      data-testid="md-content"
      className="markdown"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html }}
    />
  );
}

Markdown.propTypes = {
  content: string,
};

export default React.memo(Markdown);
