import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FullscreenIcon from '@pelckmans/business-components/icons/FullScreen';
import ExitFullscreen from '@pelckmans/business-components/icons/ExitFullScreen';
import classNames from 'classnames';
import { toggleFullscreen } from '../../../../../utils/fullscreen';

export default function FullscreenButton() {
  const { t } = useTranslation();
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    const changeHandler = () => {
      setFullscreen(prev => !prev);
    };

    if (document.addEventListener) {
      document.addEventListener('webkitfullscreenchange', changeHandler, false);
      document.addEventListener('mozfullscreenchange', changeHandler, false);
      document.addEventListener('fullscreenchange', changeHandler, false);
      document.addEventListener('msfullscreenchange', changeHandler, false);
    }

    return () => {
      document.removeEventListener('webkitfullscreenchange', changeHandler, false);
      document.removeEventListener('mozfullscreenchange', changeHandler, false);
      document.removeEventListener('fullscreenchange', changeHandler, false);
      document.removeEventListener('msfullscreenchange', changeHandler, false);
    };
  }, []);

  const onClickFullscreen = () => {
    toggleFullscreen();
  };

  return (
    <button
      className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': fullscreen })}
      onClick={onClickFullscreen}
      title={fullscreen ? t('sideBar.fullscreen.close') : t('sideBar.fullscreen.open')}
      type="button"
    >
      {fullscreen ? <ExitFullscreen /> : <FullscreenIcon />}
    </button>
  );
}
