import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const Alert = ({ message, className }) => (
  <div data-testid="alert" className={classNames('pbb-alert', className)}>
    <span dangerouslySetInnerHTML={{ __html: message }} />
  </div>
);

Alert.propTypes = {
  message: string.isRequired,
  className: string,
};

export default Alert;
