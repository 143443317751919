import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from '../services/api';
import { colorObjects } from '../enums/colors';

const CustomColorContext = createContext({
  customColors: [],
  saveCustomColor: () => {},
  allColors: [],
});

export default CustomColorContext;

async function fetchCustomColors() {
  const {
    data: { data: colors },
  } = await api.get('/studio/user/custom-colors');

  return colors;
}

async function replaceCustomColor({ id, color }) {
  return api.put(`/studio/user/custom-colors/${id}`, { color });
}

async function createCustomColor({ color, order }) {
  return api.post('/studio/user/custom-colors', { color, order });
}

export const CustomColorProvider = ({ children }) => {
  // Sealed this array because we can only have 8 custom colors -- when updating this state we need to seal the new array again...
  const [customColors, setCustomColors] = useState(Object.seal(new Array(8).fill(undefined)));

  const getCustomColors = useCallback(async () => {
    const colors = await fetchCustomColors();

    const colorsCopy = [...customColors];

    colors.forEach(color => {
      const rgbWithSpaces = color.color.split(',').join(', ');

      colorsCopy[color.order] = { ...color, color: rgbWithSpaces };
    });

    const sealedColors = Object.seal(colorsCopy);

    setCustomColors(sealedColors);

    return sealedColors;
  }, [customColors]);

  const initialFetchedRef = useRef(false);
  useEffect(() => {
    if (!initialFetchedRef.current) {
      getCustomColors();
      initialFetchedRef.current = true;
    }
  }, [getCustomColors]);

  async function saveCustomColor(newCustomColor) {
    const shouldCreate = customColors[newCustomColor.order] === undefined;

    if (shouldCreate) {
      await createCustomColor(newCustomColor);
    } else {
      await replaceCustomColor(newCustomColor);
    }

    const newColors = await getCustomColors();

    return newColors[newCustomColor.order];
  }

  const allColors = useMemo(() => {
    const definedCustomColors = customColors.filter(Boolean);

    return Object.values(colorObjects).concat(...definedCustomColors);
  }, [customColors]);

  return <CustomColorContext.Provider value={{ customColors, saveCustomColor, allColors }}>{children}</CustomColorContext.Provider>;
};

CustomColorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
