const doc = document;
const element = document.documentElement;

const isFullScreen = () => Boolean(doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement);

const body = document.body.classList;

async function requestFullScreen() {
  if (element.requestFullscreen) {
    await element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  }
  body.add('fullscreen');
}

async function exitFullScreen() {
  if (doc.exitFullscreen) {
    await doc.exitFullscreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  }
  body.remove('fullscreen');
}

export function toggleFullscreen() {
  if (isFullScreen()) {
    return exitFullScreen();
  }
  return requestFullScreen();
}

export default toggleFullscreen;
