import React, { useContext, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, bool, number, shape, string } from 'prop-types';

import PlayerMode from '../../../../enums/playerMode';
import { getPlayerMode } from '../../../../selectors/player';
import { isTeacherAlike } from '../../../../selectors/user';
import StudentDock from './StudentDock';
import TeacherDock from './TeacherDock';
import ItemCounterContext, { ItemCounterProvider } from './ItemCounterContext';
import { MobileSizeContext } from '../../context/MobileSizeContext';
import ViewModesButton from './items/ViewModesButton';

const Dock = ({ digibook }) => {
  const teacherAlike = useSelector(isTeacherAlike);
  const playerMode = useSelector(state => getPlayerMode(state));

  const isWhitepageMode = playerMode === PlayerMode.WHITEPAGE;

  const showSolutionItems = Boolean(digibook && digibook.answerLayer && !isWhitepageMode);
  const showManualItem = Boolean(digibook && digibook.manual && !isWhitepageMode);
  const showAnnotationSetItem = !isWhitepageMode && teacherAlike;

  const { isMobileWidth } = useContext(MobileSizeContext);

  const { increaseDividersCounterBy, increaseItemsCounterBy } = useContext(ItemCounterContext);
  useLayoutEffect(() => {
    if (!isWhitepageMode) {
      increaseDividersCounterBy(1);
      increaseItemsCounterBy(1);
    }

    return () => {
      if (isWhitepageMode) {
        increaseDividersCounterBy(-1);
        increaseItemsCounterBy(-1);
      }
    };
  }, [increaseDividersCounterBy, increaseItemsCounterBy, isWhitepageMode]);

  return (
    <div type="button" className="pbb-dock" data-testid="dock">
      <ul className="pbb-dock__wrapper">
        {teacherAlike ? (
          <TeacherDock digibook={digibook} showSolutionItems={showSolutionItems} showManualItem={showManualItem} showAnnotationSetItem={showAnnotationSetItem} />
        ) : (
          <StudentDock digibook={digibook} showSolutionItems={showSolutionItems} />
        )}
        {!isWhitepageMode && !isMobileWidth && (
          <>
            <li className="pbb-dock__wrapper__divider" />
            <li>
              <ViewModesButton />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

Dock.propTypes = {
  digibook: shape({
    answerLayer: string,
    teacherFeaturesEnabled: bool.isRequired,
    allowedRanges: arrayOf(
      shape({
        from: number.isRequired,
        to: number.isRequired,
      }),
    ),
    totalPages: number.isRequired,
  }),
};

const ResponsiveDock = props => (
  <ItemCounterProvider>
    <Dock {...props} />
  </ItemCounterProvider>
);

ResponsiveDock.propTypes = Dock.propTypes;

export default ResponsiveDock;
