import React, { useContext, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import classNames from 'classnames';
import { string, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import SlidingButton from './items/slidingButton';
import DrawerButtonsSidebarItem from './items/drawerButtons';
import NavigationSidebarItem from './items/navigation';
import ToolsSidebarItem from './items/tools';
import FullscreenButton from './items/fullscreenButton';

import Drawer from './drawer';

import { getTeacherFeaturesEnabledFor } from '../../../../selectors/digibooks';
import { UserSettingsContext } from '../../context/user-settings-context';
import { getIsRendered } from '../../../../selectors/player';
import { MobileSizeContext } from '../../context/MobileSizeContext';

const panelRoot = 'pbb-panel';
const SLIDING_BUTTON_HEIGHT = 70;

export default function Sidebar(props) {
  const { superModuleId, digibook } = props;
  const { sidebarAnchor, activeDrawer, setActiveDrawer, isSidebarOpen, setSidebarOpened, setShowMarkings } = useContext(UserSettingsContext);
  const { setMobileHeight } = useContext(MobileSizeContext);
  const sidebarRef = useRef(null);

  const handleResize = useCallback(() => {
    if (window.innerHeight < sidebarRef.current.offsetHeight + SLIDING_BUTTON_HEIGHT) {
      setMobileHeight(true);
    } else setMobileHeight(false);
  }, [setMobileHeight]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const bookIsRendered = useSelector(getIsRendered);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  return (
    <div className={classNames(panelRoot, `${panelRoot}--${sidebarAnchor}`)}>
      <div className="pbb-sidebar">
        {bookIsRendered && <SlidingButton superModuleId={superModuleId} slideOutDelay={500} />}
        <div ref={sidebarRef}>
          <div className="pbb-sidebar__divider" />
          <ToolsSidebarItem setShowMarkings={setShowMarkings} />
          <DrawerButtonsSidebarItem
            onClick={active => {
              if (active !== activeDrawer) {
                setActiveDrawer(active);
                if (!isSidebarOpen) setSidebarOpened(true);
              } else {
                setSidebarOpened(!isSidebarOpen);
              }
            }}
            isOpen={isSidebarOpen}
            activeDrawer={activeDrawer}
          />
          <FullscreenButton />
          <NavigationSidebarItem />
          <div className="pbb-sidebar__divider" />
          <div className="pbb-sidebar__spacer" />
        </div>
      </div>
      <Drawer isOpen={isSidebarOpen} activeDrawer={activeDrawer} superModuleId={superModuleId} digibook={digibook} teacherFeaturesEnabled={teacherFeaturesEnabled} />
    </div>
  );
}

Sidebar.propTypes = {
  // Own Props
  superModuleId: string,
  digibook: shape({
    id: string.isRequired,
    module: string.isRequired,
  }).isRequired,
};
