/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import BackgroundColorPicker from '../components/background-color-picker';
import { annotationBackgroundColors } from '../../../enums/colors';

const EDITOR_TRANSPARENT_BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.8)';

class BackgroundColorPlugin {
  selectedColor = null;

  constructor() {
    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
    this.updateEditorBackgroundColor = this.updateEditorBackgroundColor.bind(this);
  }

  init(editor) {
    this.jodit = editor;
    this.selectedColor = this.jodit.o.backgroundColor ?? annotationBackgroundColors.transparent;

    this.updateEditorBackgroundColor(this.selectedColor === annotationBackgroundColors.transparent ? EDITOR_TRANSPARENT_BACKGROUND_COLOR : this.selectedColor);

    Config.prototype.controls.backgroundcolor = {
      ...Config.prototype.controls.backgroundcolor,
      exec: () => this.jodit.selection.focus(),
      popup: (_jodit, _current, close) => {
        const root = document.createElement('div');
        root.className = 'background-color-picker__popup';
        this.colorPickerRoot = root;

        createRoot(root).render(
          <BackgroundColorPicker
            activeColor={this.selectedColor || annotationBackgroundColors.transparent}
            onColorChange={color => this.handleBackgroundColorChange(color, close)}
          />,
        );
        return root;
      },
    };
  }

  handleBackgroundColorChange(color, close) {
    const { onBackgroundColorChange } = this.jodit.options;
    this.selectedColor = color;
    this.updateEditorBackgroundColor(color === annotationBackgroundColors.transparent ? EDITOR_TRANSPARENT_BACKGROUND_COLOR : color);

    onBackgroundColorChange(color);
    close();
  }

  updateEditorBackgroundColor(color) {
    const joditContainer = document.querySelector('.jodit-react-container');
    joditContainer.style.setProperty('--jd-color-background-default', color);
  }
}

Jodit.plugins.add('backgroundcolor', BackgroundColorPlugin);
