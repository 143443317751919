import { useEffect, useMemo, useRef, useCallback } from 'react';
import throttle from 'lodash/throttle';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { getCurrentTool, getTools } from '../../../../../selectors/tools';
import Tools from '../../../../../enums/tools';
import { rgbToRgba } from '../../../../../utils/colors';

function createHighlightShape(rect, textWidth, viewPortTransform, backgroundColor, pageOffset = 0) {
  const offset = document.getElementsByClassName('textLayer')[0].getBoundingClientRect().left;

  return {
    left: (rect.left - viewPortTransform[4] - offset) / viewPortTransform[0] + pageOffset,
    top: (rect.top - viewPortTransform[5]) / viewPortTransform[0],
    width: textWidth / viewPortTransform[0],
    height: rect.height / viewPortTransform[0],
    backgroundColor,
  };
}

function isOnSameLine(rect1, rect2) {
  return rect1.y === rect2.y || (rect1.y > rect2.y && rect1.y < rect2.bottom - rect2.height / 2) || (rect1.y < rect2.y && rect2.y < rect1.bottom - rect1.height / 2);
}

function sortTextByLine(textElement1, textElement2) {
  // Sort elements by line top down and left right
  const r1 = textElement1.getBoundingClientRect();
  const r2 = textElement2.getBoundingClientRect();

  if (isOnSameLine(r1, r2)) {
    return r1.x - r2.x;
  }

  return r1.y - r2.y;
}

const MERGE_MARGIN = 10;

function mergeLines(arr, zoom) {
  return (
    arr
      .map(x => x.getBoundingClientRect())
      /**
       * Group text items per line.
       * This does not take columns into account. Column splitting is done at a later stage
       */
      .reduce((acc, curr) => {
        if (acc.find(a => a.includes(curr))) return acc;

        const line = acc.find(a => isOnSameLine(a[0], curr));

        if (line) {
          line.push(curr);
          return acc;
        }

        acc.push([curr]);

        return acc;
      }, [])
      /**
       * Sort lines from left to right.
       */
      .map(x => orderBy(x, ['left'], ['asc']))
      /**
       * Split lines on basis of horizontal proximity.
       * Columns should be separate lines after this in most cases.
       */
      .reduce((acc, curr) => {
        const newLines = [];

        for (let i = 0; i < curr.length; i += 1) {
          const line = curr[i];

          const mergable = newLines.find(l => l.some(item => item.right + MERGE_MARGIN / zoom >= line.left));

          if (mergable) {
            mergable.push(curr[i]);
          } else {
            newLines.push([curr[i]]);
          }
        }

        acc.push(...newLines);

        return acc;
      }, [])
      /**
       * Merge lines into 1 rectangle in order to capture the entirity of the text in 1 marking.
       */
      .map(line => {
        const minX = Math.min(...line.map(x => x.x));
        const maxX = Math.max(...line.map(x => x.right));
        const minY = Math.min(...line.map(x => x.y));
        const maxY = Math.max(...line.map(x => x.bottom));

        return new DOMRect(minX, minY, maxX - minX, maxY - minY);
      })
  );
}

const intersectWith = (r1, r2) => {
  if (r2.x < r1.x + r1.width && r1.x < r2.x + r2.width && r2.y < r1.y + r1.height) {
    return r1.y < r2.y + r2.height;
  }
  return false;
};

export default function useTextMarking({ viewPortTransform, saveTextHighlights, renderTempHighlights, isSinglePage, isRightPage, pageWidth, textRendered }) {
  const currentTool = useSelector(getCurrentTool);

  const startX = useRef();
  const startY = useRef();
  const tools = useSelector(getTools);
  const backgroundColor = useMemo(() => rgbToRgba(tools[currentTool]?.color.color, 0.25), [tools, currentTool]);

  const textElements = useMemo(() => {
    const [leftPage, rightPage] = [...document.getElementsByClassName('textLayer')];

    const sortedLeft = (Object.values(textRendered)[0] ? [...(leftPage?.children || [])] : []).sort(sortTextByLine);
    const sortedRight = (Object.values(textRendered)[1] ? [...(rightPage?.children || [])] : []).sort(sortTextByLine);

    const mergedLeftPage = mergeLines(sortedLeft, viewPortTransform[0]);
    const mergedRightPage = mergeLines(sortedRight, viewPortTransform[0]);

    return [...mergedLeftPage, ...mergedRightPage];
  }, [textRendered, viewPortTransform]);

  const getTextRectsFrom = useCallback(
    (e, offsetShapes) => {
      /**
       * Handler is reused between mouse and touch events.
       * We want to make sure we always have the correct coords. For touchmove/touchend events, that's the changedTouches property.
       */
      const endX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
      const endY = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

      const left = Math.min(startX.current, endX);
      const top = Math.min(startY.current, endY);
      const right = Math.max(startX.current, endX);
      const bottom = Math.max(startY.current, endY);

      const selectedRect = new DOMRect(left, top, right - left, bottom - top);

      // filter elements that match the selection.
      const selectedElements = textElements.filter(rect => intersectWith(rect, selectedRect));
      // offset drawing shapes when persisting to aws so they stay on the right page.
      const pageOffset = isSinglePage && isRightPage && offsetShapes ? pageWidth : 0;

      return selectedElements.map((boundingRect, index) => {
        // offset selection on first line if the text line has only been partially selected.
        if (index === 0 && left > boundingRect.x) {
          let newWidth = boundingRect.width - (left - boundingRect.x);
          const newRectangleX = left;
          // if only part of 1 line is selected, also offset the end of the marking.
          if (index === selectedElements.length - 1 && right < boundingRect.right) {
            newWidth -= boundingRect.right - right;
          }

          return createHighlightShape(new DOMRect(newRectangleX, boundingRect.y, newWidth, boundingRect.height), newWidth, viewPortTransform, backgroundColor, pageOffset);
        }
        // offset selection on last line if the text line has only been partially selected.
        if (index === selectedElements.length - 1 && right < boundingRect.right) {
          const newWidth = boundingRect.width - (boundingRect.right - right);

          return createHighlightShape(new DOMRect(boundingRect.x, boundingRect.y, newWidth, boundingRect.height), newWidth, viewPortTransform, backgroundColor, pageOffset);
        }

        return createHighlightShape(boundingRect, boundingRect.width, viewPortTransform, backgroundColor, pageOffset);
      });
    },
    [backgroundColor, isRightPage, isSinglePage, pageWidth, viewPortTransform, textElements],
  );

  useEffect(() => {
    let isDown;

    function handleStart(e) {
      startX.current = e.touches ? e.touches[0].clientX : e.clientX;
      startY.current = e.touches ? e.touches[0].clientY : e.clientY;
      isDown = true;
    }

    const handleMove = throttle(e => {
      if (!isDown) return;
      const shapes = getTextRectsFrom(e, false);
      renderTempHighlights(shapes);
    }, 1000 / 60);

    function handleStop(e) {
      const shapes = getTextRectsFrom(e, true);
      saveTextHighlights(shapes);
      isDown = false;
    }

    if (currentTool === Tools.TEXT_MARKER) {
      document.body.addEventListener('mousedown', handleStart);
      document.body.addEventListener('mousemove', handleMove);
      document.body.addEventListener('mouseup', handleStop);

      document.body.addEventListener('touchstart', handleStart);
      document.body.addEventListener('touchmove', handleMove);
      document.body.addEventListener('touchend', handleStop);
    }

    return () => {
      document.body.removeEventListener('mousedown', handleStart);
      document.body.removeEventListener('mousemove', handleMove);
      document.body.removeEventListener('mouseup', handleStop);

      document.body.removeEventListener('touchstart', handleStart);
      document.body.removeEventListener('touchmove', handleMove);
      document.body.removeEventListener('touchend', handleStop);
    };
  }, [getTextRectsFrom, renderTempHighlights, saveTextHighlights, currentTool]);

  return null;
}
