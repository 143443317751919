import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@pelckmans/business-components/components/label';
import { Button } from '@pelckmans/business-components/components/button';
import PropTypes from 'prop-types';

const URL_MATCHER = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;

const stripHTMLTags = str => str?.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');

function LinkDialog({ onSubmit, onDelete, onCancel, defaultUrl, defaultText }) {
  const { t } = useTranslation();
  const [url, setUrl] = useState(defaultUrl);
  const [text, setText] = useState(stripHTMLTags(defaultText));
  const invalidUrl = !URL_MATCHER.test(url);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    if (invalidUrl) return setSubmitted(true);
    return onSubmit({ url, text: text || url });
  };

  return (
    <div className="custom-link-dialog">
      <div>
        <Label htmlFor="link-url">{t('annotationTool.link.link-modal.url.label')}</Label>
        <input
          placeholder={t('annotationTool.link.link-modal.url.placeholder')}
          data-testid="link-url"
          id="link-url"
          type="text"
          className="bc-formfield__textfield"
          defaultValue={defaultUrl}
          required
          onChange={e => setUrl(e.target.value)}
        />
        <p className="bc-formfield__helperText bc-formfield__error">{submitted && invalidUrl && t('annotationTool.link.link-modal.validation.url')}</p>
      </div>
      <div>
        <Label htmlFor="link-text">{t('annotationTool.link.link-modal.text.label')}</Label>
        <input
          placeholder={t('annotationTool.link.link-modal.text.placeholder')}
          data-testid="link-text"
          id="link-text"
          type="text"
          className="bc-formfield__textfield"
          defaultValue={stripHTMLTags(defaultText)}
          onChange={e => setText(e.target.value)}
        />
      </div>
      <div className="actions">
        <Button size="small" onClick={onDelete} disabled={!defaultUrl}>
          {t('annotationTool.link.link-modal.delete')}
        </Button>
        <div className="actions__form-controls">
          <Button size="small" onClick={onCancel}>
            {t('annotationTool.link.link-modal.cancel')}
          </Button>
          <Button size="small" variant="primary" onClick={handleSubmit} type="button">
            {t('annotationTool.link.link-modal.add')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LinkDialog;

LinkDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultUrl: PropTypes.string,
  defaultText: PropTypes.string,
};
