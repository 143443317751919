function getColorValues(rgbOrRgba) {
  const matches = rgbOrRgba.match(/\d+/g).map(Number);

  return {
    r: matches[0],
    g: matches[1],
    b: matches[2],
  };
}

export function rgbToRgba(rgb, alpha) {
  if (!rgb) return undefined;
  const { r, g, b } = getColorValues(rgb);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function rgbaToRgb(rgba) {
  if (!rgba) return undefined;

  const { r, g, b } = getColorValues(rgba);

  return `rgb(${r}, ${g}, ${b})`;
}
