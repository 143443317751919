import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import { Dom } from 'jodit/esm/core/dom';
import React from 'react';
import { createRoot } from 'react-dom/client';
import LinkDialog from '../components/link-dialog';

const getParentLink = (element, editor) => Dom.closest(element, 'a', editor.editor);
const getHrefValue = string => string.match(/href="([^"]*)/)?.[1];

class LinkPlugin {
  constructor() {
    this.handleLinkInsert = this.handleLinkInsert.bind(this);
    this.handleLinkDelete = this.handleLinkDelete.bind(this);
  }

  init(editor) {
    this.jodit = editor;

    Config.prototype.controls.link = {
      ...Config.prototype.controls.link,
      exec: () => this.jodit.selection.focus(),
      popup: (_jodit, current, close) => {
        const element = document.createElement('div');
        element.className = 'custom-link-dialog__popup';
        this.colorPickerRoot = element;

        const parentLink = getParentLink(current, this.jodit);

        const selectedText = this.getSelectedText(parentLink);

        this.jodit.selection.save();

        createRoot(element).render(
          <LinkDialog
            onSubmit={this.handleLinkInsert(close)}
            onDelete={this.handleLinkDelete(close)}
            onCancel={() => close()}
            defaultUrl={parentLink?.href || getHrefValue(selectedText)}
            defaultText={selectedText}
          />,
        );
        return element;
      },
    };
  }

  getSelectedText(parentLink) {
    if (parentLink) {
      this.jodit.selection.select(parentLink);
      return parentLink.innerText;
    }
    return this.jodit.selection.isCollapsed() ? '' : this.jodit.selection.html.trim() || this.jodit.selection.text.trim();
  }

  handleLinkInsert(close) {
    return ({ url, text }) => {
      if (url && text) {
        this.jodit.selection.restore();
        const parentLink = getParentLink(this.jodit.selection.current(), this.jodit);

        if (parentLink) {
          parentLink.innerText = text;
          parentLink.setAttribute('href', url);
        } else {
          this.jodit.selection.insertHTML(`<a href="${url}">${text}</a>`);
        }

        this.jodit.selection.clear();
        this.jodit.synchronizeValues();
      }
      close();
    };
  }

  handleLinkDelete(close) {
    return () => {
      this.jodit.selection.restore();
      this.jodit.execCommand('unlink');
      close();
    };
  }
}

Jodit.plugins.add('link', LinkPlugin);
