import React, { createContext, useLayoutEffect, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useDispatch } from 'react-redux';
import { SET_VIEWMODE } from '../../../actions/actionNames';
import ViewMode from '../../../enums/viewmode';

export const MobileSizeContext = createContext({
  isMobileHeight: false,
  setMobileHeight: () => {},
  isMobileWidth: false,
  setMobileWidth: () => {},
});

export function MobileSizeProvider({ children }) {
  const [isMobileHeight, setMobileHeight] = useState(false);
  const [isMobileWidth, setMobileWidth] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobileWidth) {
      dispatch({
        type: SET_VIEWMODE,
        payload: {
          viewMode: ViewMode.PAGE,
        },
      });
    }
  }, [dispatch, isMobileWidth]);

  const value = {
    isMobileHeight,
    setMobileHeight,
    isMobileWidth,
    setMobileWidth,
  };

  useLayoutEffect(() => {
    if (isMobileWidth || isMobileHeight) {
      document.body.classList.add('mobile');
    }

    if (!isMobileWidth && !isMobileHeight) {
      document.body.classList.remove('mobile');
    }
  }, [isMobileHeight, isMobileWidth]);

  return <MobileSizeContext.Provider value={value}>{children}</MobileSizeContext.Provider>;
}

MobileSizeProvider.propTypes = {
  children: node.isRequired,
};
