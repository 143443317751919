import orderBy from 'lodash/orderBy';
import { arrayOf, bool, number, object, shape, func, string } from 'prop-types';
import { TextLayerBuilder, EventBus } from 'pdfjs-dist/web/pdf_viewer';
import 'pdfjs-dist/web/pdf_viewer.css';
import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { Util } from 'pdfjs-dist';
import { useSelector } from 'react-redux';
import { getCurrentTool } from '../../../../../selectors/tools';
import Tools from '../../../../../enums/tools';
import useTextMarking from './use-text-marking';
import { SIDEBAR_WIDTH } from '../../dialogs/mediaDialogs/chooser';
import { EMPTY_ARRAY } from '../../../../../utils/stable-default-props';

// FLAWS:
// - text outside of the book is also displayed so dragging is not possible there

/**
 * Merges answers into the existing booklayer in order without changing the order of the book layer
 */
function merge(bookText, answerText = []) {
  const returnValue = [...bookText];
  const ordered = orderBy([...bookText, ...answerText], ['transform.5', 'transform.4'], ['desc', 'asc']);

  const bookItems = answerText.map(answer => {
    const index = ordered.findIndex(x => x === answer);

    return { answer, bookItem: ordered[index - 1] };
  });

  bookItems.forEach(({ answer, bookItem }) => {
    const index = returnValue.findIndex(x => x === bookItem);

    returnValue.splice(index + 1, 0, answer);
  });

  return returnValue;
}

function contains(linkArea, text) {
  return !(text.top < linkArea.top || text.left < linkArea.left || text.bottom > linkArea.bottom || text.right > linkArea.right);
}

const eventBus = new EventBus();

export default function TextLayer({
  id,
  text,
  viewPortTransform = EMPTY_ARRAY,
  bookDimensions,
  answerText,
  answerSets = EMPTY_ARRAY,
  linkAreas = EMPTY_ARRAY,
  isSinglePage,
  isRightPage,
  renderTempHighlights,
  saveTextHighlights,
  pageWidth,
  sidebarAnchor,
}) {
  const [textLeftPage, textRightPage] = text;

  const [answersLeftPage, answersRightPage] = answerText;

  const textLayerWrapper = useRef(null);

  const initialRenderState = useMemo(() => ({ [`${id}-left-page`]: false, [`${id}-right-page`]: false }), [id]);

  const [textRendered, setTextRendered] = useState(initialRenderState);

  useTextMarking({
    isSinglePage,
    isRightPage,
    viewPortTransform,
    renderTempHighlights,
    saveTextHighlights,
    pageWidth,
    textRendered,
  });

  const renderTextLayers = useCallback(
    textLayerId => {
      setTextRendered(initialRenderState);

      const elem = document.getElementById(textLayerId);

      // clear previous text or only clear elem when right page text should be rendered
      if (isSinglePage) {
        textLayerWrapper.current.innerHTML = '';
      } else if (elem) {
        textLayerWrapper.current.removeChild(elem);
      }

      const textLayer = textLayerId === `${id}-right-page` ? textRightPage : textLeftPage;

      const answerLayer = textLayerId === `${id}-right-page` ? answersRightPage : answersLeftPage;

      let vpt = viewPortTransform;

      if (textLayerId === `${id}-right-page`) {
        vpt = [
          viewPortTransform[0],
          viewPortTransform[1],
          viewPortTransform[2],
          viewPortTransform[3],
          viewPortTransform[4] + (bookDimensions.width / 2) * viewPortTransform[0],
          viewPortTransform[5],
        ];
      }

      // THE ORDER OF THE ARGUMENTS CAN NOT BE CHANGED !!
      const appliedViewportTransform = Util.transform(vpt, textLayer.viewport.transform);

      const textLayerDiv = document.createElement('div');
      textLayerDiv.classList.add('textLayer');
      textLayerDiv.setAttribute('id', textLayerId);

      const textLayerBuilder = new TextLayerBuilder({
        textLayerDiv,
        pageIndex: 0,
        viewport: { transform: appliedViewportTransform, scale: appliedViewportTransform[0] },
        eventBus,
      });

      // deep copy to avoid multiple pushes of the answerText
      const textContent = JSON.parse(JSON.stringify(textLayer));

      if (answerLayer && answerLayer.items.length > 0) {
        textContent.items = merge(textContent.items, answerLayer.items);
        Object.assign(textContent.styles, answerLayer.styles);
      }

      textLayerBuilder.setTextContent(textContent);

      textLayerBuilder.render();

      textLayerWrapper.current.appendChild(textLayerDiv);
    },
    [viewPortTransform, textRightPage, textLeftPage, answersRightPage, answersLeftPage, bookDimensions, isSinglePage, initialRenderState, id],
  );

  const onTextRendered = textLayerId => x => {
    if (x.source.renderingDone) {
      setTextRendered(state => ({ ...state, [textLayerId]: true }));
    }
  };

  useEffect(() => {
    const handleTextRendered = onTextRendered(`${id}-left-page`);

    if (textLeftPage && textLeftPage.items.length > 0) {
      eventBus.on('textlayerrendered', handleTextRendered);

      renderTextLayers(`${id}-left-page`);
    }
    return () => {
      eventBus.off('textlayerrendered', handleTextRendered);
    };
  }, [textLeftPage, renderTextLayers, id]);

  useEffect(() => {
    const handleTextRendered = onTextRendered(`${id}-right-page`);

    if (textRightPage && textRightPage.items.length > 0) {
      eventBus.on('textlayerrendered', handleTextRendered);

      renderTextLayers(`${id}-right-page`);
    }

    return () => {
      eventBus.off('textlayerrendered', handleTextRendered);
    };
  }, [textRightPage, renderTextLayers, id]);

  const getAbsoluteBoundingBox = useCallback(
    rectangleShape => {
      const shapeLeft = isSinglePage && isRightPage ? rectangleShape.left - 0.5 : rectangleShape.left;
      const fullBookWidth = bookDimensions.width * (isSinglePage ? 2 : 1);
      const offset = sidebarAnchor === 'left' ? SIDEBAR_WIDTH : 0;

      // shape * scale of viewportTransform and additional offset value for x and y
      const top = rectangleShape.top * bookDimensions.height * viewPortTransform[0] + viewPortTransform[5];
      const left = shapeLeft * fullBookWidth * viewPortTransform[0] + viewPortTransform[4] + offset;
      const width = rectangleShape.width * fullBookWidth * viewPortTransform[0] + offset;
      const height = rectangleShape.height * bookDimensions.height * viewPortTransform[0];

      return {
        left,
        top,
        right: left + width,
        bottom: top + height,
      };
    },
    [bookDimensions.height, bookDimensions.width, isRightPage, isSinglePage, sidebarAnchor, viewPortTransform],
  );

  const overlappingAreas = useMemo(() => {
    const absoluteLinkAreas = linkAreas.map(linkArea =>
      getAbsoluteBoundingBox({
        ...linkArea.shape,
      }),
    );

    const absoluteAnswerSets = answerSets.map(answerSet =>
      getAbsoluteBoundingBox({
        ...answerSet.shape,
        width: answerSet.shape.size,
        // we multiply the size by 1.4 to mimic the height of the answer stepper
        height: answerSet.shape.size * 1.4,
      }),
    );

    return [...absoluteLinkAreas, ...absoluteAnswerSets];
  }, [getAbsoluteBoundingBox, linkAreas, answerSets]);

  const currentTool = useSelector(getCurrentTool);

  useEffect(() => {
    const noPointerEvents = overlappingAreas.length > 0 && isSinglePage ? textRendered[`${id}-left-page`] : textRendered[`${id}-left-page`] && textRendered[`${id}-right-page`];

    if (noPointerEvents) {
      const textElements = document.querySelectorAll(`#text-layers-${id} span`);
      const [parentElement] = document.querySelectorAll(`#text-layers-${id} div`);

      if (parentElement) {
        textElements.forEach(element => {
          const elementRect = element.getBoundingClientRect();

          const rect = {
            top: elementRect.top,
            left: elementRect.left,
            right: elementRect.right,
            bottom: elementRect.bottom,
          };

          if (currentTool === Tools.TEXT_MARKER || overlappingAreas.some(areaRect => contains(areaRect, rect))) {
            // eslint-disable-next-line no-param-reassign
            element.style.pointerEvents = 'none';
          }
        });
      }
    }
  }, [textRendered, isSinglePage, viewPortTransform, currentTool, overlappingAreas, id]);

  return <div id={`text-layers-${id}`} ref={textLayerWrapper} />;
}

TextLayer.propTypes = {
  id: string.isRequired,
  text: arrayOf(object).isRequired,
  viewPortTransform: arrayOf(number),
  bookDimensions: shape({
    width: number,
    height: number,
  }).isRequired,
  answerText: arrayOf(object).isRequired,
  linkAreas: arrayOf(object),
  answerSets: arrayOf(object),
  isSinglePage: bool.isRequired,
  isRightPage: bool.isRequired,
  renderTempHighlights: func.isRequired,
  saveTextHighlights: func.isRequired,
  pageWidth: number.isRequired,
  sidebarAnchor: string.isRequired,
};
