import NoteIcon from '@pelckmans/business-components/icons/Note';
import { oneOf, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, closePopUpNote } from '../../../../../actions/dialog';
import { deleteNote } from '../../../../../actions/notes';
import Alert from '../../../../../components/alert';
import TranslationButton from '../../../../../components/translation-button';
import dialogTypes from '../../../../../enums/dialogTypes';
import { getNoteById } from '../../../../../selectors/notes';
import { getTOCNodeById } from '../../../../../selectors/toc';
import { formatDate } from '../../../../../utils/intl';
import GenericModal from '../common/GenericModal';
import Tab from '../tabs/tab';
import Tabs from '../tabs/tabs';
import Info from './Info';

const tabs = {
  INFO: 'info',
  DELETE: 'delete',
};

const Modal = ({ noteId, tocNodeId, activeTab }) => {
  const dispatch = useDispatch();
  const tocNode = useSelector(state => getTOCNodeById(state)(tocNodeId));
  const note = useSelector(state => getNoteById(state, noteId));

  const [t] = useTranslation();
  const [currentTab, setCurrentTab] = useState(tabs.INFO);

  const date = formatDate(new Date(note.updatedAt));
  const modalTitle = `${t('notes.note')} ${date}`;

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  const handleClose = useCallback(() => dispatch(closeDialog(note.id, dialogTypes.NOTE_OPTIONS)), [dispatch, note.id]);

  const handleDelete = useCallback(() => {
    dispatch(
      deleteNote({
        noteId: note.id,
        nodeId: tocNode.id,
      }),
    );
    dispatch(closePopUpNote(note.id));
    handleClose();
  }, [note.id, tocNode.id, dispatch, handleClose]);

  const footer = useMemo(() => {
    switch (currentTab) {
      case tabs.DELETE:
        return (
          <>
            <TranslationButton onClick={handleClose} label="notes.modal.buttons.cancel" className="pbb-btn--inverted" data-testid="cancel-btn" />
            <TranslationButton onClick={handleDelete} label="notes.modal.buttons.delete" className="pbb-btn--danger" data-testid="delete-btn" />
          </>
        );
      case tabs.INFO:
      default:
        return <TranslationButton onClick={handleClose} label="notes.modal.buttons.close" className="pbb-btn--inverted" data-testid="close-btn" />;
    }
  }, [handleClose, handleDelete, currentTab]);

  const tabBar = useMemo(
    () => (
      <Tabs onClick={setCurrentTab} activeTab={currentTab}>
        <Tab title={t('tabs.info.title')} id={tabs.INFO} icon="icon-info" />
        <Tab title={t('tabs.delete')} id={tabs.DELETE} icon="icon-trash" />
      </Tabs>
    ),
    [currentTab, t],
  );

  const tabContent = useMemo(() => {
    switch (currentTab) {
      case tabs.DELETE:
        return <Alert message={t('notes.modal.alerts.delete')} />;
      case tabs.INFO:
      default:
        return <Info note={note} tocNode={tocNode} />;
    }
  }, [currentTab, t, note, tocNode]);

  return (
    <GenericModal icon={<NoteIcon/>} close={handleClose} title={modalTitle} footerContent={footer} tabBar={tabBar} contentModifier="pbb-modal-note">
      {tabContent}
    </GenericModal>
  );
};

Modal.propTypes = {
  noteId: string.isRequired,
  tocNodeId: string.isRequired,
  activeTab: oneOf([tabs.INFO, tabs.DELETE]),
};

export default Modal;
