import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PrintIcon from '@pelckmans/business-components/icons/Print';
import ArrowLeftIcon from '@pelckmans/business-components/icons/ArrowLeft';
import ArrowRightIcon from '@pelckmans/business-components/icons/ArrowRight';
import { getCurrentPageNumber } from '../../../../../../selectors/navigation';
import { getActiveNodes } from '../../../../../../selectors/toc';
import { gotoPreviousPage, gotoNextPage, setCurrentPage } from '../../../../../../actions/navigation';
import { openPrintModal } from '../../../../../../actions/dialog';
import PageInput from './pageInput';
import { PageEventContext } from '../../../../context/PageEventContext';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../enums/analytics';
import { EMPTY_ARRAY } from '../../../../../../utils/stable-default-props';

const Footer = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const activeNodes = useSelector(state => getActiveNodes(state) || EMPTY_ARRAY);
  const currentPageNumber = useSelector(state => getCurrentPageNumber(state) || 0);

  const { capturePageEvent } = useContext(PageEventContext);

  const handlePrevious = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_ARROWS);
    dispatch(gotoPreviousPage());
  };

  const handleSet = pageNumber => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_DIRECT);
    dispatch(setCurrentPage(pageNumber));
  };

  const handleNext = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_ARROWS);
    dispatch(gotoNextPage());
  };

  return (
    <div className="pbb-footer">
      <div className="pbb-mask" />

      <div className="pbb-footer__wrapper">
        <button
          className="footer__button"
          type="button"
          onClick={() => dispatch(openPrintModal(activeNodes))}
          data-testid="btn-print"
          title={t('drawer.toc.footer.buttons.print.tooltip')}
        >
          <PrintIcon />
        </button>

        <div className="pbb-page-jump">
          <div className="pbb-page__navigator pbb-page__navigator--left">
            <button type="button" onClick={() => handlePrevious()} data-testid="btn-previous" title={t('drawer.toc.footer.buttons.backward.tooltip')}>
              <ArrowLeftIcon />
            </button>
          </div>
          <PageInput currentPageNumber={currentPageNumber} onSubmit={p => handleSet(p)} />
          <div className="pbb-page__navigator pbb-page__navigator--right">
            <button type="button" onClick={() => handleNext()} data-testid="btn-next" title={t('drawer.toc.footer.buttons.forward.tooltip')}>
              <ArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
