export const getManualResizeConfig = () => ({
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: true,
  bottomRight: true,
  bottomLeft: false,
  topLeft: false,
});
