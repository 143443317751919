/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import { Plugin } from 'jodit/esm/core/plugin';

const fontFamilyList = {
  OpenSans: 'annotationTool.font.sansSerif',
  TimesNewRoman: 'annotationTool.font.serif',
  NotoSansMono: 'annotationTool.font.monospace',
  Playwrite: 'annotationTool.font.handwritten',
};

class FontFamilyPlugin extends Plugin {
  init(editor) {
    const { t } = editor.options;

    Config.prototype.controls.fontFamily = {
      ...Config.prototype.controls.font,
      tooltip: t('annotationTool.buttons.tooltip.fontFamily'),
      list: {
        'Open Sans': t(fontFamilyList.OpenSans),
        'Times New Roman': t(fontFamilyList.TimesNewRoman),
        NotoSansMono: t(fontFamilyList.NotoSansMono),
        Playwrite: t(fontFamilyList.Playwrite),
      },
    };
  }

  afterInit(editor) {
    const { t } = editor.options;

    editor.toolbar.buttons
      .find(x => x.name === 'fontFamily')
      .state.on('change.value', (property, prevValue, nextValue) => {
        const fontLabel = t(fontFamilyList[nextValue.replace(/['"]+/g, '').replace(/ /g, '')]);

        const fontFamilyIconWrapper = document.querySelector('.jodit-icon.jodit-icon_fontFamily').parentElement;

        fontFamilyIconWrapper.innerHTML = `<span class="jodit-icon jodit-icon_fontFamily" data-style="${nextValue}" style="font-family: ${nextValue}">${fontLabel}</span>`;
      });
  }

  beforeDestruct() {}
}

Jodit.plugins.add('fontFamily', FontFamilyPlugin);
