import { TRANSPARENT } from '../constants/constants';

const colorNames = {
  GREEN: 'green',
  YELLOW: 'yellow',
  ORANGE: 'orange',
  RED: 'red',
  BLUE: 'blue',
  PURPLE: 'purple',
  BLACK: 'black',
  GREY: 'grey',
};

export const backgroundColors = {
  WHITE: 'white',
  TRANSPARENT: 'transparent',
};

export const colorObjects = {
  green: { id: 'green', color: 'rgb(103, 194, 55)', order: 0 },
  yellow: { id: 'yellow', color: 'rgb(248, 231, 28)', order: 1 },
  orange: { id: 'orange', color: 'rgb(246, 176, 39)', order: 2 },
  red: { id: 'red', color: 'rgb(214, 3, 30)', order: 3 },
  blue: { id: 'blue', color: 'rgb(77, 172, 198)', order: 4 },
  purple: { id: 'purple', color: 'rgb(197, 15, 228)', order: 5 },
  black: { id: 'black', color: 'rgb(0, 0, 0)', order: 6 },
  grey: { id: 'grey', color: 'rgb(230, 230, 230)', order: 7 },
};

export const annotationBackgroundColors = {
  white: 'rgb(255, 255, 255)',
  transparent: TRANSPARENT,
};

export default colorNames;
