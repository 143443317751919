import React from 'react';
import JWPlayerReact from '@jwplayer/jwplayer-react';
import { bool, object, string } from 'prop-types';
import classNames from 'classnames';

function JWPlayer({ id, config, playlist, file, audioOnly = false }) {
  const jwplayerConfig = {
    displaydescription: false,
    displaytitle: false,
    autostart: false,
    ...(audioOnly
      ? {
          width: 'auto',
          height: 40,
          logo: undefined,
          primary: 'html5',
        }
      : {}),
    ...config,
  };

  const onReady = () => {
    const allVideoTags = [...document.querySelectorAll('video')];

    // video/audio may only be autoplay if no other video tag is playing.
    // html5 video tag has no playing property. It has a paused property :)
    // so all paused props should be true to make this new player autostart
    const autoPlay = allVideoTags.every(x => x.paused);

    const player = window.jwplayer(id);

    if (!player.isMuted && autoPlay) {
      player.play();
    }
  };

  return (
    <div className={classNames('jw-player', { 'jw-player--audio': audioOnly })}>
      <div className="jw-player__container">
        <JWPlayerReact id={id} library="https://content.jwplatform.com/libraries/zyraJCSg.js" config={jwplayerConfig} playlist={playlist} file={file} onReady={onReady} />
      </div>
    </div>
  );
}

JWPlayer.propTypes = {
  id: string.isRequired,
  playlist: string, // used for converted video/audio playback on jwplayer
  file: string, // used for AWS S3 hosted media
  audioOnly: bool,
  config: object,
};

export default JWPlayer;
