import React, { createContext, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { node } from 'prop-types';

import { getCurrentDigibook } from '../../../selectors/digibooks';
import { getCurrentPageNumber, getViewMode, canToggleSpreadPageMode } from '../../../selectors/navigation';
import ViewMode from '../../../enums/viewmode';
import useAnalytics from '../../../hooks/useAnalytics';
import { ANALYTICS_EVENT_TYPES } from '../../../enums/analytics';

const TIMEOUT = 3000;

export const PageEventContext = createContext({
  capturePageEvent: () => {},
});

const PageEventContextProvider = ({ children }) => {
  const currentPage = useSelector(getCurrentPageNumber);
  const digibook = useSelector(getCurrentDigibook);
  const viewmode = useSelector(getViewMode);
  const spreadModeEnabled = useSelector(canToggleSpreadPageMode);

  const timeoutRef = useRef(null);
  const currentPageRef = useRef(0);

  const analytics = useAnalytics();

  // Ref should be updated whenever the page changes
  currentPageRef.current = currentPage;

  const capturePageEvent = useCallback(
    subLocationId => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        const page = currentPageRef.current;
        const subObjectId = viewmode === ViewMode.SPREAD && spreadModeEnabled ? `${page}-${page + 1}` : `${page}`;

        const event = {
          eventType: ANALYTICS_EVENT_TYPES.DIGIBOOK_PAGE_VIEWED,
          objectId: digibook.id,
          subObjectId,
        };

        if (subLocationId) event.subLocationId = subLocationId;

        analytics.capture(event);
      }, TIMEOUT);
    },
    [analytics, digibook.id, spreadModeEnabled, viewmode],
  );

  return <PageEventContext.Provider value={{ capturePageEvent }}>{children}</PageEventContext.Provider>;
};

PageEventContextProvider.propTypes = {
  children: node.isRequired,
};

export default PageEventContextProvider;
