import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { func, string, shape, oneOfType, number } from 'prop-types';
import { useSelector } from 'react-redux';
import Sizes from '../../../../../enums/sizes';
import Tools, { opaqueTools } from '../../../../../enums/tools';
import { EMPTY_OBJECT } from '../../../../../utils/stable-default-props';
import ColorPicker from '../../../../../components/color-picker/color-picker';
import { getTools } from '../../../../../selectors/tools';
import { colorObjects } from '../../../../../enums/colors';
import CustomColorContext from '../../../../../contexts/CustomColorContext';

const ToolOptions = ({ variant, onColorClick, onSizeClick, currentOptions = EMPTY_OBJECT }) => {
  const { saveCustomColor, customColors } = useContext(CustomColorContext);
  const [t] = useTranslation();
  const tools = useSelector(getTools);
  const tool = tools[variant];

  return (
    <>
      {variant !== Tools.TEXT_MARKER && (
        <>
          <div className={classNames('pbb-menu__sizes', `pbb-menu__sizes--${variant}`)}>
            {Object.values(Sizes).map(size => (
              <button
                key={size}
                onClick={() => onSizeClick(size)}
                type="button"
                title={t(`sizes.tool.${size}`)}
                className={classNames(`size--${size}`, 'pbb-sidebar__button', {
                  'pbb-sidebar__button--active': currentOptions.size === size,
                })}
              >
                <span className="indicator" style={{ backgroundColor: tool?.color?.color }} />
              </button>
            ))}
          </div>
          <div className="divider-line" />
        </>
      )}
      <ColorPicker
        activeColor={tool?.color}
        defaultColors={Object.values(colorObjects)}
        customColors={customColors}
        onColorSelect={onColorClick}
        saveCustomColor={saveCustomColor}
        isOpaque={opaqueTools.includes(variant)}
        circled={variant === Tools.PENCIL}
      />
    </>
  );
};

ToolOptions.propTypes = {
  variant: string.isRequired,
  onColorClick: func.isRequired,
  onSizeClick: func.isRequired,
  currentOptions: shape({
    color: shape({
      id: oneOfType([string, number]).isRequired,
      color: string.isRequired,
      order: number.isRequired,
    }),
    size: string,
    backgroundColor: string,
  }),
};

export default ToolOptions;
