import { useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import throttle from 'lodash/throttle';
import { calibratePoint, normalizeRotation, radiansToDegrees } from '../utils';
import { getTools, getCurrentTool } from '../../../../../../selectors/tools';
import { brushSizes } from '../../../../../../enums/sizes';
import Tools from '../../../../../../enums/tools';
import CustomColorContext from '../../../../../../contexts/CustomColorContext';
import { rgbToRgba } from '../../../../../../utils/colors';

/**
 * This function is a bit like magic. It solves the problem we create using the Math.atan2() function.
 *    This function returns us a rotation angle between -180 and 180 degrees in relation to the positive X-axis on a Cartesian coordinate system.
 *    A problem occurs when we cross the negative X-axis since a jump of rotation will appear from 180 to -180 degrees or vice versa
 *    This makes it hard to draw a complete circle of 360 degrees
 * Using the function solves this
 */
const mod = (a, n) => a - Math.floor(a / n) * n;

function calculateArcAngles({ mousePoint, mouseAngle, startAngle, endAngle }) {
  const mouseRotInRadians = Math.atan2(mousePoint.y, mousePoint.x);

  const angles = {};

  const mouseAngleInDegrees = normalizeRotation(radiansToDegrees(mouseRotInRadians));

  const deltaDegrees = mod(mouseAngleInDegrees - mouseAngle + 180, 360) - 180;
  angles.mouseAngle = mouseAngle + deltaDegrees;

  if (deltaDegrees < 0) {
    if (mouseAngle === 0) angles.startAngle = angles.mouseAngle;
    else if (angles.mouseAngle < startAngle) angles.startAngle = angles.mouseAngle;
  }
  if (deltaDegrees > 0) {
    if (mouseAngle === 0) angles.endAngle = angles.mouseAngle;
    else if (angles.mouseAngle >= endAngle) angles.endAngle = angles.mouseAngle;
  }

  return angles;
}

const calculateCircleCoords = (bookOffsets, centerPoint) => {
  const { x: centerX, y: centerY } = centerPoint;
  const { left: bookLeft, top: bookTop, scale } = bookOffsets;

  return {
    x: (centerX - bookLeft) / scale,
    y: (centerY - bookTop) / scale,
  };
};

const SUPPORTED_DRAWING_TOOLS = [Tools.MARKER, Tools.PENCIL, Tools.CLASSIC_ERASER];

function useDraftingCompassToDraw({ degrees, mathToolsRef, drawTempArc, rotate, viewPortTransform, finishDrawing }) {
  const tools = useSelector(getTools);
  const currentTool = useSelector(getCurrentTool);

  const canDraw = useMemo(() => SUPPORTED_DRAWING_TOOLS.includes(currentTool), [currentTool]);
  const strokeWidth = useMemo(() => {
    if (!canDraw) return undefined;

    const currentToolOpts = tools[currentTool];
    return brushSizes[currentTool][currentToolOpts.size];
  }, [canDraw, currentTool, tools]);

  const { allColors } = useContext(CustomColorContext);

  const color = useMemo(() => {
    if (!canDraw) return undefined;

    const currentToolOpts = tools[currentTool];

    switch (currentTool) {
      case Tools.PENCIL:
        return allColors.find(c => c.id === currentToolOpts.color.id).color;
      case Tools.MARKER:
        return rgbToRgba(allColors.find(c => c.id === currentToolOpts.color.id).color, 0.25);
      case Tools.CLASSIC_ERASER:
        return 'white';
      default:
        return 'white';
    }
  }, [allColors, canDraw, currentTool, tools]);

  const isDrawingRef = useRef(false);
  const sideBarOffsetRef = useRef();
  const anglesRef = useRef({
    startAngle: degrees,
    endAngle: degrees,
    mouseAngle: degrees,
  });
  const centerPointRef = useRef({
    x: 0,
    y: 0,
  });
  const bookOffsetRef = useRef({
    left: viewPortTransform[4],
    top: viewPortTransform[5],
    scale: viewPortTransform[0],
  });

  const handleMove = throttle(e => {
    e.preventDefault();
    rotate();

    if (!isDrawingRef.current || !canDraw) return;

    const { startAngle, endAngle, mouseAngle } = anglesRef.current;
    const { clientX, clientY } = e.changedTouches?.[0] || e;

    const calibratedMousePoint = calibratePoint({ x: clientX - sideBarOffsetRef.current, y: clientY }, centerPointRef.current);

    anglesRef.current = { ...anglesRef.current, ...calculateArcAngles({ mousePoint: calibratedMousePoint, mouseAngle, startAngle, endAngle }) };

    const { x: circleX, y: circleY } = calculateCircleCoords(bookOffsetRef.current, centerPointRef.current);

    drawTempArc({
      startAngle: anglesRef.current.startAngle,
      endAngle: anglesRef.current.endAngle,
      left: circleX,
      top: circleY,
      stroke: color,
      strokeWidth,
      globalCompositeOperation: currentTool === Tools.CLASSIC_ERASER ? 'destination-out' : 'source-over',
    });
  });

  function handleStop(e) {
    e.preventDefault();
    isDrawingRef.current = false;

    if (canDraw) {
      const { x: circleX, y: circleY } = calculateCircleCoords(bookOffsetRef.current, centerPointRef.current);

      finishDrawing({ top: circleY, left: circleX });
    }

    document.body.removeEventListener('mousemove', handleMove);
    document.body.removeEventListener('touchmove', handleMove);

    document.body.removeEventListener('mouseup', handleStop);
    document.body.removeEventListener('touchend', handleStop);
  }

  function handleStart(mouseDownEvent) {
    mouseDownEvent.preventDefault();
    isDrawingRef.current = true;

    anglesRef.current = {
      startAngle: degrees,
      endAngle: degrees,
      mouseAngle: degrees,
    };

    const { x: centerX, y: centerY, width: centerWidth } = document.getElementById('center-dot').getBoundingClientRect();
    sideBarOffsetRef.current = mathToolsRef.current.parentElement.getBoundingClientRect().x;

    centerPointRef.current = {
      x: centerX + centerWidth / 2 - sideBarOffsetRef.current,
      y: centerY + centerWidth / 2,
    };

    bookOffsetRef.current = {
      left: viewPortTransform[4],
      top: viewPortTransform[5],
      scale: viewPortTransform[0],
    };

    document.body.addEventListener('mousemove', handleMove);
    document.body.addEventListener('touchmove', handleMove);

    document.body.addEventListener('mouseup', handleStop);
    document.body.addEventListener('touchend', handleStop);
  }

  return handleStart;
}

export default useDraftingCompassToDraw;
