import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMenu, MenuGroup, MenuItem } from '@pelckmans/business-components/components/action-menu';
import { Button } from '@pelckmans/business-components/components/button';
import AnnotationSetIcon from '@pelckmans/business-components/icons/AnnotationSet';
import classNames from 'classnames';
import { openAnnotationSetsManagementModal } from '../../../../../actions/dialog';
import { getActiveAnnotationSet, getAnnotationSets } from '../../../../../selectors/annotationSets';
import { setActiveAnnotationSet } from '../../../../../actions/annotationSets';

export default function AnnotationSetsButton() {
  const { t } = useTranslation();
  const annotationSets = useSelector(getAnnotationSets);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openAnnotationSetsManagementModal());
  };

  const onItemClick = annotationSet => {
    dispatch(setActiveAnnotationSet(annotationSet));
  };

  return (
    <ActionMenu
      title="Menu"
      menuClassName="dock-flyout-menu annotation-sets-menu"
      action={
        <button type="button" title={t('dock.buttons.annotationSets.tooltip')}>
          <AnnotationSetIcon data-testid="annotation-set-icon" />
        </button>
      }
      setDownOverflow
      boundingBoxPadding="10"
      portal
    >
      <MenuItem data-testid="menu-title" className="annotation-sets-menu__header">
        {t('dock.actionMenu.annotationSets.title')}
      </MenuItem>
      <MenuGroup takeOverflow>
        {annotationSets.map(annotationSet => (
          <React.Fragment key={annotationSet.id}>
            <MenuItem
              className={classNames('dock-flyout-menu__item', { 'dock-flyout-menu__item--active': activeAnnotationSet.id === annotationSet.id })}
              onClick={() => onItemClick(annotationSet)}
              title={annotationSet.name}
            >
              <AnnotationSetIcon />
              {annotationSet.name}
            </MenuItem>
          </React.Fragment>
        ))}
      </MenuGroup>
      <MenuItem className="annotation-sets-menu__button" title="">
        <Button size="small" onClick={handleClick} title={t('dock.actionMenu.annotationSets.managementButton')}>
          {t('dock.actionMenu.annotationSets.managementButton')}
        </Button>
      </MenuItem>
    </ActionMenu>
  );
}
