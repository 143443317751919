import { node } from 'prop-types';
import React, { createContext, useCallback, useState, useEffect, useContext, useLayoutEffect } from 'react';
import { MobileSizeContext } from '../../context/MobileSizeContext';

const ItemCounterContext = createContext({ itemsCount: 0, dividersCount: 0, increaseItemsCounterBy: () => {}, increaseDividersCounterBy: () => {} });

const removePixelUnitFromStyle = cssValue => Number(cssValue.replace('px', ''));
const WHITESPACE_DOCK = 32;

const calculateDockWidth = (amountOfItems, amountOfDividers) => {
  const computedStyle = window.getComputedStyle(document.documentElement);

  const sidebarWidth = removePixelUnitFromStyle(computedStyle.getPropertyValue('--sidebar-width'));
  const dockPaddingInline = removePixelUnitFromStyle(computedStyle.getPropertyValue('--dock-padding-inline'));
  const dockItemWidth = removePixelUnitFromStyle(computedStyle.getPropertyValue('--dock-item-width'));
  const dockDividerWidth = removePixelUnitFromStyle(computedStyle.getPropertyValue('--dock-divider-width'));
  const dockDividerMargin = removePixelUnitFromStyle(computedStyle.getPropertyValue('--dock-divider-margin-inline'));

  return (
    sidebarWidth +
    WHITESPACE_DOCK +
    (dockPaddingInline + amountOfItems * dockItemWidth + amountOfDividers * (dockDividerWidth + dockDividerMargin * 2) + dockPaddingInline) +
    WHITESPACE_DOCK +
    sidebarWidth
  );
};

export function ItemCounterProvider({ children }) {
  const [itemsCounter, setItemsCounter] = useState(0);
  const [dividersCounter, setDividersCounter] = useState(0);

  const { setMobileWidth } = useContext(MobileSizeContext);

  const increaseItemsCounterBy = useCallback(amount => {
    setItemsCounter(prev => prev + amount);
  }, []);

  const increaseDividersCounterBy = useCallback(amount => {
    setDividersCounter(prev => prev + amount);
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth < calculateDockWidth(itemsCounter, dividersCounter)) {
      setMobileWidth(true);
    } else {
      setMobileWidth(false);
    }
  }, [dividersCounter, itemsCounter, setMobileWidth]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return <ItemCounterContext.Provider value={{ increaseItemsCounterBy, increaseDividersCounterBy }}>{children}</ItemCounterContext.Provider>;
}

ItemCounterProvider.propTypes = {
  children: node.isRequired,
};

export default ItemCounterContext;
