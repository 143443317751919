import React from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ManualLayerButton } from './components/ManualLayerButton';
import { PopUpManualButton } from './components/PopUpManualButton';
import { getIsManualVisible } from '../../../../../../selectors/player';
import { toggleManual, togglePopUpManual } from '../../../../../../actions/player';

export default function ManualButton({ manualType }) {
  const isManualVisible = useSelector(state => getIsManualVisible(state));
  const dispatch = useDispatch();

  if (manualType === 'manual-layer') {
    return <ManualLayerButton isEnabled={isManualVisible} onClick={() => dispatch(toggleManual())} />;
  }

  return <PopUpManualButton isEnabled={isManualVisible} onClick={() => dispatch(togglePopUpManual())} />;
}

ManualButton.propTypes = {
  manualType: string.isRequired,
};
