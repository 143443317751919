import EllipsisVerticalIcon from '@pelckmans/business-components/icons/EllipsisVertical';
import { bool, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import NotLicensedModal from '../../../dialogs/NotLicensedModal';
import SlideSetInfo from './SlideSetInfo';

const UnlicenseSlideSetListItem = ({ slideSet, isTeacher }) => {
  const { name } = slideSet;

  const [notLicensedModalVisible, setNotLicensedModalVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      {notLicensedModalVisible &&
        createPortal(
          <NotLicensedModal title={t('notLicensed.accessContent.title')} message={t('notLicensed.accessContent.message')} onClose={() => setNotLicensedModalVisible(false)} />,
          document.body,
        )}
      <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
        <div className="pbb-list__link-wrap pbb-list__link-wrap--disabled">
          <button type="button" className="pbb-list__link pbb-flex-wrap" title={name} onClick={() => setNotLicensedModalVisible(true)}>
            <SlideSetInfo slideSet={slideSet} />
            {isTeacher && (
              <div className="pbb-list__actions">
                <div className="pbb-list__action">
                  <EllipsisVerticalIcon />
                </div>
              </div>
            )}
          </button>
        </div>
      </li>
    </>
  );
};

UnlicenseSlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
  isTeacher: bool.isRequired,
};

export default UnlicenseSlideSetListItem;
