/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { createPortal } from 'react-dom';

import api from '../services/api';

function PrintPages({ digibook, from, to, includeAnswers, onAfterPrint }) {
  const iframeRef = useRef(null);
  const styleRef = useRef(null);
  const renderTaskCount = useRef(null);
  const isSafari = window.safari !== undefined || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [imageSources, setImageSources] = useState();
  const [imagesRendered, setImagesRendered] = useState(false);

  useEffect(() => {
    async function getImageSources() {
      const backCoverPage = digibook.totalPages + 1;
      const fromPage = from === 0 ? 1 : from;
      const toPage = to === backCoverPage ? digibook.totalPages : to;
      const pageRange = to ? `${fromPage}-${toPage}` : fromPage;

      const includesCover = from === 0;
      const includesBackCover = to === backCoverPage;
      const onlyBackCoverRequested = from === backCoverPage;
      const onlyCoverRequested = includesCover && !to;

      const routes = [];
      if (!onlyCoverRequested && !onlyBackCoverRequested) {
        routes.push(`/studio/digibooks/${digibook.id}/book/print-pages/${pageRange}${includeAnswers ? '?includeAnswers=true' : ''}`);
      }

      if (includesCover || onlyCoverRequested) routes.unshift(`/studio/digibooks/${digibook.id}/cover/print-pages/1`);
      if (includesBackCover || onlyBackCoverRequested) routes.push(`/studio/digibooks/${digibook.id}/backcover/print-pages/1`);

      const opts = { headers: { Authorization: `Bearer ${digibook.systemToken}` } };
      const responses = await Promise.all(
        routes.map(async route => {
          const response = await api.get(route, opts);
          return Object.values(response.data);
        }),
      );

      const data = [];
      responses.forEach(res => data.push(...res));
      renderTaskCount.current = data.length;

      setImageSources(data);

      if (styleRef.current) {
        styleRef.current.textContent = `
        body {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
      }
      * {
          box-sizing: border-box;
          -moz-box-sizing: border-box;
      }
      .page {
          width: 210mm;
          min-height: 296mm;
          max-height: ${isSafari ? '290mm' : '296mm'}
      }
      .subpage {
          height: 296mm;
          max-height: ${isSafari ? '290mm' : '296mm'}
      }

      .subpage img {
        width: 100%;
        height: 100%;
        display: block;
        margin: 0 auto;
        padding: 0;
      }

      @page {
          size: A4;
          margin: 0;
          padding: 0;
      }
      @media print {
          html, body {
              width: 210mm;
              height: ${isSafari ? '294mm' : '296mm'};
          }
          .page {
              margin: 0;
              padding 0;
              border: initial;
              border-radius: initial;
              width: initial;
              min-height: initial;
              box-shadow: initial;
              background: initial;
              page-break-after: always;
          }
      }
        `;
      }
    }

    getImageSources();
  }, [digibook, from, to, includeAnswers, isSafari]);

  useEffect(() => {
    if (imagesRendered) {
      if (isSafari) {
        window.addEventListener('afterprint', function handleWindowFocus() {
          onAfterPrint();
          window.removeEventListener('afterprint', handleWindowFocus);
        });
      } else {
        window.addEventListener('focus', function handleWindowFocus() {
          onAfterPrint();
          window.removeEventListener('focus', handleWindowFocus);
        });
      }

      iframeRef.current.contentWindow.print();
    }
  }, [imagesRendered, onAfterPrint, isSafari]);

  return (
    <iframe style={{ display: 'none' }} data-testid="iframe" ref={iframeRef} title="print-pages">
      {imageSources &&
        createPortal(
          <>
            <style id="my-style" ref={styleRef} />
            {imageSources.map(url => {
              return (
                <div className="page" key={url}>
                  <div className="subpage">
                    <img
                      src={url}
                      key={url}
                      alt="printing-page"
                      onLoad={() => {
                        renderTaskCount.current -= 1;
                        if (renderTaskCount.current === 0) setImagesRendered(true);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>,
          iframeRef.current.contentWindow.document.body,
        )}
    </iframe>
  );
}

PrintPages.propTypes = {
  digibook: PropTypes.shape({
    id: PropTypes.string.isRequired,
    systemToken: PropTypes.string.isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number,
  includeAnswers: PropTypes.bool,
  onAfterPrint: PropTypes.func.isRequired,
};

export default PrintPages;
