import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { shape, func, string, arrayOf, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

import { UserSettingsContext } from '../../../../context/user-settings-context';
import Alert from '../../../../../../components/alert';
import Collapsible from '../../../../../../components/collapsible';
import TocNodeTitle from '../tocnode-title';
import MedialinkList from './components/medialink/MedialinkList';
import UserMaterialList from './components/user-material/UserMaterialList';
import SharedMediaList from './components/SharedMediaList';
import UserMaterialPropType from './components/user-material/user-material-prop-type';
import { EMPTY_ARRAY } from '../../../../../../utils/stable-default-props';

const DEFAULT_TOC_NODE = {
  title: '',
  prefix: '',
  displayPrefix: false,
};

const MaterialList = ({
  medialinks = EMPTY_ARRAY,
  tocnode = DEFAULT_TOC_NODE,
  sharedMedia = EMPTY_ARRAY,
  teacherFeaturesEnabled = false,
  medialinksFetched = false,
  openFilterSettings,
  materialCategoriesToHide = EMPTY_ARRAY,
  disableCollapse = false,
  moduleId,
  userMedia = EMPTY_ARRAY,
}) => {
  const [t] = useTranslation();
  const userMediaFeatureToggleEnabled = useSelector(state => state.featureToggles.USER_MEDIA);
  const SHARED_MEDIA_CATEGORY_KEY = teacherFeaturesEnabled ? t('drawer.material.sharedMedia') : t('drawer.material.sharedByTeacher');
  const { materialSorting, showOnlyFavoriteMaterial } = useContext(UserSettingsContext);

  const currentMedialinks = useMemo(
    () =>
      medialinks
        .map(ml => ({ ...ml, normalizedCategory: (ml.contentType && ml.contentType.category && ml.contentType.category.toUpperCase()) || t('drawer.material.withoutCategory') }))
        .filter(ml => (showOnlyFavoriteMaterial ? ml.isFavorite : true))
        .filter(ml => materialCategoriesToHide.indexOf(ml.normalizedCategory) === -1),
    [medialinks, materialCategoriesToHide, showOnlyFavoriteMaterial, t],
  );

  const currentSharedMedia = useMemo(() => orderBy(sharedMedia, ['lastUpdatedAt'], ['desc']), [sharedMedia]);

  const currentUserMaterial = useMemo(() => {
    const sortedUserMaterial = orderBy(userMedia, ['lastUpdatedAt'], ['desc']);

    if (showOnlyFavoriteMaterial) {
      return sortedUserMaterial.filter(media => media.isFavorite);
    }

    return sortedUserMaterial;
  }, [userMedia, showOnlyFavoriteMaterial]);

  const categoryFilterOn = materialCategoriesToHide.length > 0;

  const shouldHideOwnMedia = materialCategoriesToHide.indexOf(SHARED_MEDIA_CATEGORY_KEY.toUpperCase()) > -1;

  const renderNoMaterialMessage = () => {
    if (showOnlyFavoriteMaterial) return <Alert message={t('drawer.material.no_favorites')} className="pbb-alert--spaced" />;

    if (!categoryFilterOn || (!userMediaFeatureToggleEnabled && sharedMedia.length > 0 && !shouldHideOwnMedia))
      return <Alert message={t('drawer.material.no_material')} className="pbb-alert--spaced" />;

    return (
      <div className="pbb-alert pbb-alert--spaced">
        {t('drawer.material.allMaterialHiddenBecauseFilters.text')}
        <button className="pbb-btn pbb-btn--inverted pbb-btn--icon-right" type="button" title={t('drawer.material.footer.settings')} onClick={() => openFilterSettings()}>
          {t('drawer.material.allMaterialHiddenBecauseFilters.button')}
          <i className="icon-bb-material-show" />
        </button>
      </div>
    );
  };

  const renderUserMaterialOrSharedMedia = () => {
    if (shouldHideOwnMedia) return null;

    if (userMediaFeatureToggleEnabled) {
      return (
        <UserMaterialList
          userMaterials={currentUserMaterial}
          allowCollapsing={currentMedialinks.length > 0}
          teacherFeaturesEnabled={teacherFeaturesEnabled}
          showOnlyFavoriteMaterial={showOnlyFavoriteMaterial}
          moduleId={moduleId}
          nodeId={tocnode.id}
        />
      );
    }

    if (currentSharedMedia.length) {
      return <SharedMediaList sharedMedia={currentSharedMedia} allowCollapsing={currentMedialinks.length > 0} teacherFeaturesEnabled={teacherFeaturesEnabled} />;
    }

    return null;
  };

  const renderContent = () => {
    if (!medialinksFetched) return null;

    const showNoMaterialMessage = currentMedialinks.length === 0 && (shouldHideOwnMedia || (userMediaFeatureToggleEnabled ? currentUserMaterial.length === 0 : true));
    return (
      <>
        {showNoMaterialMessage && renderNoMaterialMessage()}
        {renderUserMaterialOrSharedMedia()}
        {currentMedialinks.length > 0 && (
          <MedialinkList medialinks={currentMedialinks} sortedOn={materialSorting} allowCollapsing={currentSharedMedia.length > 0} ownMediaHidden={shouldHideOwnMedia} />
        )}
      </>
    );
  };

  return (
    <Collapsible.Item disableCollapse={disableCollapse}>
      <Collapsible.Title>
        <span className="pbb-collapsible__title-text">
          {tocnode.displayPrefix && <span className="pbb-collapsible__subtitle">{tocnode.prefix}</span>}
          <span className="pbb-collapsible__title">
            <TocNodeTitle tocNode={{ id: tocnode.id, title: tocnode.title }} />
          </span>
        </span>
      </Collapsible.Title>
      <Collapsible.Content>{renderContent()}</Collapsible.Content>
    </Collapsible.Item>
  );
};

MaterialList.propTypes = {
  medialinks: arrayOf(
    shape({
      id: string,
      name: string,
      contentType: shape({
        category: string,
      }),
    }),
  ),
  tocnode: shape({
    id: string,
    title: string,
    prefix: string,
    displayPrefix: bool,
  }),
  sharedMedia: arrayOf(
    shape({
      id: string,
      name: string,
      href: string,
    }),
  ),
  openFilterSettings: func.isRequired,
  teacherFeaturesEnabled: bool,
  medialinksFetched: bool,
  materialCategoriesToHide: arrayOf(string),
  disableCollapse: bool,
  moduleId: string.isRequired,
  userMedia: arrayOf(UserMaterialPropType),
};

MaterialList.displayName = 'Collapsible-MaterialList';

export default MaterialList;
