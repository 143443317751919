import React from 'react';

import RectangleTransparentIcon from '@pelckmans/business-components/icons/RectangleTransparent';
import RectangleIcon from '@pelckmans/business-components/icons/RectangleOutline';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { annotationBackgroundColors } from '../../../enums/colors';

const BackgroundColorPicker = ({ onColorChange, activeColor }) => {
  const { t } = useTranslation();
  return (
    <div className="color-picker__popup background-color-picker">
      {Object.entries(annotationBackgroundColors).map(([colorKey, colorValue]) => (
        <button
          data-testid={`background-color-${colorKey}`}
          key={colorKey}
          className={classNames('color-picker__button', { 'color-picker__button--active': colorValue === activeColor })}
          type="button"
          onClick={() => {
            onColorChange(colorValue);
          }}
        >
          {colorValue === annotationBackgroundColors.transparent ? (
            <RectangleTransparentIcon title={t(`backgroundColors.${colorKey}`)} className="background-color-icon" key={colorKey} />
          ) : (
            <RectangleIcon title={t(`backgroundColors.${colorKey}`)} className="background-color-icon" key={colorKey} />
          )}
        </button>
      ))}
    </div>
  );
};

export default BackgroundColorPicker;

BackgroundColorPicker.propTypes = {
  activeColor: PropTypes.string,
  onColorChange: PropTypes.func.isRequired,
};
