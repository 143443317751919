import React from 'react';
import { useTranslation } from 'react-i18next';
import { shape, string, func, bool } from 'prop-types';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { Button } from '@pelckmans/business-components/components/button';

import UserMaterialInfo from './UserMaterialInfo';

const UserMediaPreviewModal = ({ onClose, userMaterial, isOwner }) => {
  const [t] = useTranslation();

  const handleDownload = () => {
    window.open(userMaterial.signedUrls.downloadUrl, '_blank');
  };

  return (
    <ModalDialog id="user-media-preview-modal" onClose={onClose} closeViaEscape zIndex={10001} closeTitle={t('actions.close')}>
      <Dialog.Header data-testid="dialog-header" title={userMaterial.name} startAdornment={<i role="img" className="icon-bb-material-own" />} />
      <Dialog.Body data-testid="dialog-body">
        <div className="pbb-modal__body">
          <div className="pbb-modal__body-wrapper">
            <UserMaterialInfo userMaterial={userMaterial} isOwner={isOwner} editable={false} />
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer data-testid="dialog-footer">
        <Button variant="primary" onClick={handleDownload}>
          <i className="pbb-list__link-icon icon-bb-modal-download" />
          {t('options.download')}
        </Button>
      </Dialog.Footer>
    </ModalDialog>
  );
};

UserMediaPreviewModal.propTypes = {
  onClose: func.isRequired,
  userMaterial: shape({
    id: string.isRequired,
    name: string.isRequired,
    file: shape({
      id: string.isRequired,
      extension: string.isRequired,
      mimeType: string.isRequired,
      originalName: string.isRequired,
    }).isRequired,
    module: string.isRequired,
    node: string.isRequired,
    signedUrls: shape({
      downloadUrl: string.isRequired,
    }).isRequired,
  }).isRequired,
  isOwner: bool.isRequired,
};

export default UserMediaPreviewModal;
