import { Button } from '@pelckmans/business-components/components/button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RgbStringColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';

const ColorWheel = ({ onClose, onConfirm, activeColor }) => {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState(activeColor);

  return (
    <>
      <RgbStringColorPicker onChange={setSelectedColor} color={selectedColor} />
      <div className="actions">
        <Button size="small" onClick={onClose} className="button__cancel">
          {t('colorPicker.buttons.cancel')}
        </Button>
        <Button variant="primary" size="small" className="button__confirm" onClick={() => onConfirm(selectedColor)}>
          {t('colorPicker.buttons.confirm')}
        </Button>
      </div>
    </>
  );
};

ColorWheel.propTypes = {
  activeColor: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ColorWheel;
