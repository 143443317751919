import React, { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import classNames from 'classnames';
import { bool, number, arrayOf, shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SolutionMaskIcon from '@pelckmans/business-components/icons/LightBulbMask';
import SolutionUnmaskIcon from '@pelckmans/business-components/icons/LightBulbUnmask';
import SolutionStepperIcon from '@pelckmans/business-components/icons/LightBulbWithArrowRight';
import SolutionPageIcon from '@pelckmans/business-components/icons/LightBulbWithCheckMarkOnPage';

import { getPageNumbersToShow } from '../../../../../../selectors/rendering';
import { getCurrentTool, getAnswerStepperMode } from '../../../../../../selectors/tools';
import { getIsSolutionsPageVisible } from '../../../../../../selectors/player';
import { getCurrentPageNumber, getViewMode } from '../../../../../../selectors/navigation';

import { TOGGLE_ANSWER_REVEAL, TOGGLE_ANSWER_HIDE, TOGGLE_ANSWER_STEPPER, HIDE_ALL_ANSWERS, TOGGLE_SOLUTIONS_PAGE, ZOOM_TO_FIT } from '../../../../../../actions/actionNames';
import { setCurrentPage, changeViewMode } from '../../../../../../actions/navigation';
import { setCurrentTool } from '../../../../../../actions/tools';

import Tools from '../../../../../../enums/tools';
import VIEWMODES from '../../../../../../enums/viewmode';
import SolutionsToggleButton from './components/SolutionsToggleButton';
import ItemCounterContext from '../../ItemCounterContext';

export default function SolutionsPanel({ digibook, isMobileWidth }) {
  const { teacherFeaturesEnabled, allowedRanges } = digibook;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const currentPage = useSelector(getCurrentPageNumber);
  const currentPages = useSelector(getPageNumbersToShow);
  const currentTool = useSelector(getCurrentTool);
  const answerStepperMode = useSelector(getAnswerStepperMode);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);
  const viewMode = useSelector(getViewMode);

  const { increaseItemsCounterBy } = useContext(ItemCounterContext);
  useLayoutEffect(() => {
    if (!teacherFeaturesEnabled) {
      increaseItemsCounterBy(2);
    } else {
      increaseItemsCounterBy(5);
    }

    return () => {
      if (!teacherFeaturesEnabled) {
        increaseItemsCounterBy(-2);
      } else {
        increaseItemsCounterBy(-5);
      }
    };
  }, [increaseItemsCounterBy, teacherFeaturesEnabled]);

  const revealableCurrentPages = useMemo(() => currentPages.filter(page => allowedRanges?.some(range => range.from <= page && range.to >= page)), [currentPages, allowedRanges]);

  const handleToggleSolutionsPage = () => {
    if (viewMode !== VIEWMODES.PAGE) {
      dispatch(changeViewMode(VIEWMODES.PAGE));

      if (currentPage !== currentPages[0]) {
        dispatch(setCurrentPage(currentPages[0]));
      }
    }

    dispatch(setCurrentTool(Tools.POINTER));

    dispatch({ type: ZOOM_TO_FIT });

    if (answerStepperMode) dispatch({ type: TOGGLE_ANSWER_STEPPER });

    dispatch({
      type: HIDE_ALL_ANSWERS,
      payload: {
        pageNumbers: revealableCurrentPages,
      },
    });

    dispatch({ type: TOGGLE_SOLUTIONS_PAGE });
  };

  useEffect(() => {
    // when navigating from a page with the solutions page visible to a page without the solutions page visible
    // we need to toggle the solutions page visibility again
    if (isSolutionsPageVisible && revealableCurrentPages.length === 0) {
      dispatch({ type: TOGGLE_SOLUTIONS_PAGE });
    }
  }, [dispatch, isSolutionsPageVisible, revealableCurrentPages.length]);

  if (isMobileWidth) return <SolutionsToggleButton digibook={digibook} />;

  return (
    <>
      <SolutionsToggleButton digibook={digibook} />

      {teacherFeaturesEnabled && (
        <>
          <button
            type="button"
            disabled={revealableCurrentPages.length === 0 || isSolutionsPageVisible}
            className={classNames({ 'pbb-active': currentTool === Tools.ANSWER_REVEAL })}
            onClick={() => dispatch({ type: TOGGLE_ANSWER_REVEAL })}
            title={t('dock.items.solutions.buttons.answerLayerUnmaskButton.tooltip')}
          >
            <SolutionUnmaskIcon />
          </button>
          <button
            type="button"
            disabled={revealableCurrentPages.length === 0 || isSolutionsPageVisible}
            className={classNames({ 'pbb-active': currentTool === Tools.ANSWER_HIDE })}
            onClick={() => dispatch({ type: TOGGLE_ANSWER_HIDE })}
            title={t('dock.items.solutions.buttons.answerLayerMaskButton.tooltip')}
          >
            <SolutionMaskIcon />
          </button>
          <button
            type="button"
            disabled={revealableCurrentPages.length === 0 || isSolutionsPageVisible}
            className={classNames({ 'pbb-active': answerStepperMode })}
            onClick={() => dispatch({ type: TOGGLE_ANSWER_STEPPER })}
            title={t('dock.items.solutions.buttons.answerStepperToggleButton.tooltip')}
          >
            <SolutionStepperIcon />
          </button>
        </>
      )}

      <button
        type="button"
        disabled={revealableCurrentPages.length === 0 || viewMode === VIEWMODES.SCROLL}
        className={classNames({ 'pbb-active': isSolutionsPageVisible })}
        onClick={handleToggleSolutionsPage}
        title={
          isSolutionsPageVisible
            ? t('dock.items.solutions.SolutionsPanel.buttons.solutionsPageToggle.hide.tooltip')
            : t('dock.items.solutions.SolutionsPanel.buttons.solutionsPageToggle.reveal.tooltip')
        }
      >
        <SolutionPageIcon />
      </button>
    </>
  );
}

SolutionsPanel.propTypes = {
  digibook: shape({
    teacherFeaturesEnabled: bool.isRequired,
    allowedRanges: arrayOf(
      shape({
        from: number.isRequired,
        to: number.isRequired,
      }),
    ),
    totalPages: number.isRequired,
  }).isRequired,
  isMobileWidth: bool.isRequired,
};
