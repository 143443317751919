import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { string, func, number, shape, bool } from 'prop-types';

import { normalizeFloat } from '../utils/normalizeFloat';
import POSITIONS from '../enums/position';
import tools from '../enums/tools';
import PDFPageProviderService from '../services/PDFPageProviderService';

import renderOffscreen from '../modules/player/services/offscreen-renderer';
import FabricService from '../modules/player/services/fabric-service';

export class ImageViewer extends PureComponent {
  state = {
    zoomLevel: 1,
    zoomToFitMode: true,
    zoomToSelectionMode: false,
  };

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    const { src, id, modalPosition } = this.props;
    const { zoomLevel } = this.state;

    this.service = new FabricService(id);
    this.service.initialize(this.wrapper.current.clientHeight - 50, this.wrapper.current.clientWidth);
    this.mounted = PDFPageProviderService.fetchAndParseFile(src)
      .promise.then(proxy => proxy.getPage(1))
      .then(page => renderOffscreen(page).promise)
      .then(({ dataUrl }) => this.service.renderPDFPage(dataUrl, POSITIONS.LEFT, false))
      .then(() => {
        this.service.addDragListeners(
          {
            [tools.ZOOM_SELECT]: nextZoom =>
              this.setState({
                zoomLevel: nextZoom,
                zoomToSelectionMode: false,
              }),
          },
          this.panHandler,
        );
        this.service.addPinchListeners(this.pinchHandler, modalPosition);
        this.service.scaleCanvasToFit(zoomLevel);

        this.service.renderAll();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { zoomLevel: oldZoomLevel, zoomToFitMode: wasZoomedToFit, zoomToSelectionMode: wasZoomToSelectionMode } = prevState;
    const { zoomLevel, zoomToFitMode, zoomToSelectionMode } = this.state;
    const { dialogSize: oldDialogSize, isFullScreen: wasFullScreen, modalPosition: oldModalPosition } = prevProps;
    const { dialogSize, isFullScreen, modalPosition } = this.props;
    const zoomChanged = oldZoomLevel !== zoomLevel;
    const undidPanning = zoomToFitMode && !wasZoomedToFit;
    const dialogSizeChanged = oldDialogSize && (dialogSize.width !== oldDialogSize.width || dialogSize.height !== oldDialogSize.height);
    const fullScreenChanged = wasFullScreen !== isFullScreen;
    const zoomToSelectionModeToggled = wasZoomToSelectionMode !== zoomToSelectionMode;
    const modalPositionChanged = oldModalPosition && (modalPosition.x !== oldModalPosition.x || modalPosition.y !== oldModalPosition.y);

    if (zoomToSelectionModeToggled) {
      this.service.setCurrentTool(zoomToSelectionMode ? tools.ZOOM_SELECT : undefined);
    }

    if (fullScreenChanged) {
      this.zoomToFit();
    }

    if (zoomChanged) {
      this.service.setZoom(zoomLevel);
    }

    if (dialogSizeChanged) {
      this.service.handleCanvasResize({
        height: this.wrapper.current.clientHeight - 50,
        width: this.wrapper.current.clientWidth,
      });
    }

    if (undidPanning) {
      this.service.scaleCanvasToFit(this.service.zoomLevel);
    }

    if (modalPositionChanged || fullScreenChanged) {
      this.service.removePinchListeners();
      this.service.addPinchListeners(this.pinchHandler, isFullScreen ? { x: 0, y: 0 } : modalPosition);
    }

    this.service.renderAll();
  }

  componentWillUnmount() {
    this.service.dispose();
  }

  zoomIn = () => {
    const { zoomLevel } = this.state;
    const nextZoom = normalizeFloat(zoomLevel + 0.1);

    this.setState({
      zoomLevel: nextZoom > 3 ? 3 : nextZoom,
    });
  };

  zoomOut = () => {
    const { zoomLevel } = this.state;
    const nextZoom = normalizeFloat(zoomLevel - 0.1);

    this.setState({
      zoomLevel: nextZoom < 0.2 ? 0.2 : nextZoom,
    });
  };

  zoomToFit = () =>
    this.setState({
      zoomLevel: 1,
      zoomToFitMode: true,
      zoomToSelectionMode: false,
    });

  setZoomToSelectionMode = () =>
    this.setState(state => ({
      zoomToSelectionMode: !state.zoomToSelectionMode,
      zoomToFitMode: false,
    }));

  panHandler = () =>
    this.setState({
      zoomToFitMode: false,
    });

  pinchHandler = zoomLevel => {
    this.setState({ zoomLevel });
  };

  render() {
    const { id, onFullScreenClick, close, isFullScreen, t } = this.props;
    const { zoomToSelectionMode } = this.state;

    return (
      <div
        ref={this.wrapper}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <canvas id={id} />
        <div className="pbb-modal__placeholder">
          <ul className="pbb-modal__photo-tools">
            <li>
              <button type="button" data-testid="zoom-in" onClick={this.zoomIn} title={t('components.imageViewer.buttons.zoomIn.tooltip')}>
                <i className="icon-dock-zoom-in" />
              </button>
            </li>
            <li>
              <button type="button" data-testid="zoom-out" onClick={this.zoomOut} title={t('components.imageViewer.buttons.zoomOut.tooltip')}>
                <i className="icon-dock-zoom-out" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className={zoomToSelectionMode ? 'pbb-active' : ''}
                onClick={this.setZoomToSelectionMode}
                data-testid="zoom-drag"
                title={t('components.imageViewer.buttons.setZoomToSelectionMode.tooltip')}
              >
                <i className="icon-bb-dock-zoom-drag" />
              </button>
            </li>
            <li>
              <button type="button" data-testid="zoom-to-fit" onClick={this.zoomToFit} title={t('components.imageViewer.buttons.zoomToFit.tooltip')}>
                <i className="icon-dock-zoom-1-on-1" />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={onFullScreenClick}
                data-testid="fullscreen-expander"
                title={isFullScreen ? t('components.imageViewer.buttons.minimize.tooltip') : t('components.imageViewer.buttons.maximize.tooltip')}
              >
                <i className={isFullScreen ? 'icon-bb-reduce' : 'icon-dock-expander'} />
              </button>
            </li>
          </ul>
        </div>
        {isFullScreen && (
          <button type="button" className="close" data-dismiss="pbb-modal" data-testid="close-fullscreen" aria-label="Close" onClick={close} title={t('options.close')}>
            <div className="close-icon">
              <i className="icon-submodule-close" />
            </div>
          </button>
        )}
      </div>
    );
  }
}

ImageViewer.propTypes = {
  src: string.isRequired,
  id: string.isRequired,
  onFullScreenClick: func.isRequired,
  isFullScreen: bool.isRequired,
  dialogSize: shape({ width: number, height: number }).isRequired,
  close: func.isRequired,
  t: func.isRequired,
  modalPosition: shape({ x: number, y: number }),
};

export default withTranslation()(ImageViewer);
