import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ZoomToFitIcon from '@pelckmans/business-components/icons/ZoomToFit';
import { bool } from 'prop-types';
import { zoomToFit } from '../../../../../../actions/navigation';

function ZoomToFitButton({ disabled }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <button type="button" onClick={() => dispatch(zoomToFit())} title={t('dock.buttons.zoomReset.tooltip')} disabled={disabled}>
      <ZoomToFitIcon />
    </button>
  );
}

ZoomToFitButton.propTypes = {
  disabled: bool,
};

export default ZoomToFitButton;
