import React from 'react';
import { ActionMenu, MenuItem } from '@pelckmans/business-components/components/action-menu';
import BookScrollModeIcon from '@pelckmans/business-components/icons/BookScrollMode';
import BookSinglePageModeIcon from '@pelckmans/business-components/icons/BookSinglePageMode';
import BookSpreadModeIcon from '@pelckmans/business-components/icons/BookSpreadMode';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import VIEW_MODE from '../../../../../enums/viewmode';
import PLAYER_MODE from '../../../../../enums/playerMode';
import { getViewMode } from '../../../../../selectors/navigation';
import { getIsSolutionsPageVisible, getPlayerMode } from '../../../../../selectors/player';
import { changeViewMode } from '../../../../../actions/navigation';

const ICONS_BY_VIEW_MODE = {
  [VIEW_MODE.PAGE]: BookSinglePageModeIcon,
  [VIEW_MODE.SPREAD]: BookSpreadModeIcon,
  [VIEW_MODE.SCROLL]: BookScrollModeIcon,
};

function ViewModesButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const viewMode = useSelector(getViewMode);
  const playerMode = useSelector(getPlayerMode);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);

  const isActionButtonDisabled = playerMode === PLAYER_MODE.WHITEPAGE || isSolutionsPageVisible;
  const ViewModeIcon = ICONS_BY_VIEW_MODE[viewMode];

  return (
    <ActionMenu
      title="viewModeMenu"
      menuClassName="dock-flyout-menu"
      action={
        <button type="button" title={t('dock.buttons.viewModes.tooltip')} disabled={isActionButtonDisabled}>
          <ViewModeIcon />
        </button>
      }
      setDownOverflow
      boundingBoxPadding="10"
      portal
    >
      {Object.entries(ICONS_BY_VIEW_MODE).map(([mode, Icon]) => (
        <MenuItem
          className={classNames('dock-flyout-menu__item', { 'dock-flyout-menu__item--active': viewMode === mode })}
          key={mode}
          onClick={() => dispatch(changeViewMode(mode))}
          title={t(`dock.actionMenu.viewModes.${mode}`)}
        >
          <Icon />
          {t(`dock.actionMenu.viewModes.${mode}`)}
        </MenuItem>
      ))}
    </ActionMenu>
  );
}

export default ViewModesButton;
