import update from 'immutability-helper';

import {
  CLOSE_DIALOG,
  CLOSE_MATERIAL_ASSIGNMENT,
  CLOSE_MINISITE,
  CLOSE_POP_UP_NOTE,
  MEDIALINKS_CLOSE_CHOOSER,
  MEDIALINKS_MOVE_DIALOG_TO_FRONT,
  MEDIALINKS_OPEN_CHOOSER,
  MOVE_POP_UP_NOTE_TO_FRONT,
  OPEN_DIALOG,
  OPEN_MATERIAL_ASSIGNMENT,
  OPEN_MINISITE,
  TOGGLE_POP_UP_MANUAL,
} from '../actions/actionNames';
import dialogTypes from '../enums/dialogTypes';
import ENTITY_TYPE from '../enums/entityType';

const initialState = {
  openDialogs: [],
  openMinisite: undefined,
  medialinksChooser: undefined,
  openMaterialAssignment: undefined,
};

const bringActiveDialogToFront = state => (medialinkId, dt) =>
  state.openDialogs.map(({ id, dialogType, ...rest }) => ({
    id,
    dialogType,
    ...rest,
    isInFront: medialinkId === id && dialogType === dt,
  }));

export default function dialogsReducer(state = initialState, action) {
  const bringToFront = bringActiveDialogToFront(state);
  switch (action && action.type) {
    case TOGGLE_POP_UP_MANUAL: {
      const isPopUpManualVisible = state.openDialogs.some(d => d.id === 'pop-up-manual');

      if (isPopUpManualVisible) {
        return update(state, {
          openDialogs: {
            $set: state.openDialogs.filter(d => d.id !== 'pop-up-manual'),
          },
        });
      }

      return update(state, {
        openDialogs: {
          $set: [
            ...state.openDialogs,
            {
              id: 'pop-up-manual',
              isInFront: true,
              modal: false,
              entityType: ENTITY_TYPE.POP_UP_MANUAL,
            },
          ],
        },
      });
    }
    case OPEN_DIALOG: {
      const { id: medialinkId, dialogType, ...restOfPayload } = action.payload;
      const dialogIsOpen = state.openDialogs.some(({ id, dialogType: dt }) => (medialinkId ? id === medialinkId && dt === dialogType : dt === dialogType));

      if (!dialogIsOpen) {
        const closedDialogs = state.openDialogs
          .filter(d => !d.modal)
          .map(({ id, ...rest }) => ({
            id,
            ...rest,
            isInFront: false,
          }));

        return update(state, {
          openDialogs: {
            $set: [
              ...closedDialogs,
              {
                ...restOfPayload,
                id: medialinkId,
                isInFront: true,
                dialogType,
                modal: dialogType !== undefined,
              },
            ],
          },
          medialinksChooser: {
            // make sure opening a medialink closes the chooser if it should be opened
            $set: undefined,
          },
        });
      }

      return update(state, {
        openDialogs: {
          $set: [...bringToFront(medialinkId, dialogType)],
        },
      });
    }
    case CLOSE_DIALOG: {
      const { id: medialinkId, dialogType } = action.payload;

      const index = state.openDialogs.findIndex(({ id, dialogType: dt }) => (medialinkId ? id === medialinkId && dt === dialogType : dt === dialogType));

      if (index >= 0) {
        return update(state, {
          openDialogs: {
            $splice: [[index, 1]],
          },
        });
      }
      return state;
    }
    case MEDIALINKS_MOVE_DIALOG_TO_FRONT: {
      const { id: medialinkId, dialogType } = action.payload;
      const index = state.openDialogs.findIndex(({ id, dialogType: dt }) => id === medialinkId && dt === dialogType);
      if (index >= 0) {
        return update(state, {
          openDialogs: {
            $set: [...bringToFront(medialinkId, dialogType)],
          },
        });
      }
      return state;
    }
    case OPEN_MINISITE: {
      const { medialink, miniSiteUrl } = action.payload;

      return update(state, {
        openMinisite: {
          $set: {
            current: medialink.id,
            [medialink.id]: {
              medialink,
              miniSiteUrl,
            },
          },
        },
      });
    }
    case CLOSE_MINISITE: {
      const { medialinkId } = action.payload;
      if (state.openMinisite.current === medialinkId) {
        return update(state, { openMinisite: { $unset: ['current'] } });
      }
      return state;
    }
    case MEDIALINKS_OPEN_CHOOSER: {
      const { medialinkIds, title, mousePosition, linkAreaId } = action.payload;

      return update(state, {
        medialinksChooser: {
          $set: {
            current: linkAreaId,
            [linkAreaId]: {
              medialinkIds,
              title,
              mousePosition,
              linkAreaId,
            },
          },
        },
      });
    }
    case MEDIALINKS_CLOSE_CHOOSER: {
      const { linkAreaId } = action.payload;
      if (state.medialinksChooser.current === linkAreaId) {
        return update(state, { medialinksChooser: { $unset: ['current'] } });
      }
      return state;
    }
    case OPEN_MATERIAL_ASSIGNMENT: {
      return update(state, {
        openDialogs: { $set: state.openDialogs.map(d => ({ ...d, isInFront: false })) },
        openMaterialAssignment: {
          $set: {
            material: { ...action.payload.material, entityType: action.payload.entityType },
            assignment: action.payload.assignment,
            owner: action.payload.owner,
          },
        },
        medialinksChooser: { $set: undefined },
      });
    }
    case CLOSE_MATERIAL_ASSIGNMENT: {
      return update(state, { openMaterialAssignment: { $set: undefined } });
    }
    // TODO refactor to use CLOSE DIALOG
    case CLOSE_POP_UP_NOTE: {
      const { id } = action.payload;

      const updatedDialogs = state.openDialogs.filter(dialog => !(dialog.id === id && dialog.dialogType === dialogTypes.POP_UP_NOTE));

      return update(state, {
        openDialogs: {
          $set: updatedDialogs,
        },
      });
    }
    // TODO refactor to use CLOSE DIALOGs
    case MOVE_POP_UP_NOTE_TO_FRONT: {
      const { id } = action.payload;

      const updatedDialogs = state.openDialogs.map(dialog => ({
        ...dialog,
        isInFront: dialog.id === id,
      }));

      return update(state, {
        openDialogs: {
          $set: updatedDialogs,
        },
      });
    }

    default: {
      return state;
    }
  }
}
