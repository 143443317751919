import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { arrayOf, bool, node, oneOfType } from 'prop-types';
import AccessDeniedComponent from './access-denied-error';

export const PrivateRoute = ({ isAuthenticated = false, children = null, ...rest }) => {
  if (!isAuthenticated) {
    return <AccessDeniedComponent />;
  }

  return <Route {...rest}>{children}</Route>;
};

PrivateRoute.propTypes = {
  isAuthenticated: bool,
  children: oneOfType([arrayOf(node), node]),
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
