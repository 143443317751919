import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { string, shape } from 'prop-types';
import { getModuleById } from '../../../selectors/module';

function PlayerTitle({ superModule }) {
  const [t] = useTranslation();

  useEffect(() => {
    const names = [t('products.digibook')];

    if (superModule) names.push(superModule.name);

    document.title = names.join(' - ');
  }, [superModule, t]);

  return null;
}

const mapStateToProps = (state, ownProps) => ({
  superModule: ownProps.superModuleId && getModuleById(state, ownProps.superModuleId),
});

PlayerTitle.propTypes = {
  // Connected props
  superModule: shape({
    name: string.isRequired,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  superModuleId: string,
};

export default connect(mapStateToProps)(PlayerTitle);
