/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Dom } from 'jodit/esm/core/dom';
import AdvancedColorPicker from '../../color-picker/color-picker';
import { colorObjects } from '../../../enums/colors';

class FontColorPlugin {
  selectedColor = undefined;

  constructor() {
    this.handleColorSelect = this.handleColorSelect.bind(this);
  }

  init(editor) {
    this.jodit = editor;

    this.selectedColor = colorObjects.black;

    this.jodit.events.on('afterInit', () => {
      // this.getCaretColor() does not work since the first node is the current selection at this point
      const lastTextNode = Dom.last(this.jodit.editor, node => Dom.isText(node));
      if (lastTextNode) {
        this.setIndicatorColor(lastTextNode.parentElement.style.color);
      }

      this.jodit.events.on(this.jodit.editor, 'click', () => {
        const caretColor = this.getCaretColor();
        this.setIndicatorColor(caretColor);
      });
    });

    this.jodit.events.on('afterCommand.delete', command => {
      if (command === 'delete') {
        this.selectedColor = colorObjects.black;

        // when color picker is open and a delete command is fired the default behavior of jodit is to set the font color to black
        // this part of the code is not in context of react, thus no rerender of the color picker will happen
        // this is why i set the correct classes manually on a delete command, so that the correct selected color is made active in de color picker
        // this only happens when the color picker is open
        const activeColor = document.querySelector('.active-button');
        if (activeColor) {
          activeColor.classList.remove('active-button');
          activeColor.childNodes[0].classList.remove('active');
          activeColor.childNodes[0].style.outline = 'none';
        }

        const blackColor = document.querySelector(`.color[title="${this.jodit.o.t('colors.black').toLowerCase()}"]`);
        if (blackColor) {
          blackColor.classList.add('active-button');
          blackColor.childNodes[0].classList.add('active');
          blackColor.childNodes[0].style.outline = `2px solid ${colorObjects.black.color}`;
        }
      }
    });

    Config.prototype.controls.fontcolor = {
      ...Config.prototype.controls.fontcolor,
      exec: () => this.jodit.selection.focus(),
      popup: (_jodit, _current, close) => {
        const { customColors, saveCustomColor } = this.jodit.options;

        const selectedTextColor = this.getCaretColor();
        if (selectedTextColor) {
          this.setIndicatorColor(selectedTextColor);
        }

        this.initializeSelectedColor();

        const element = document.createElement('div');
        element.className = 'color-picker__popup';
        this.colorPickerRoot = element;

        const handleSaveCustomColor = async color => saveCustomColor(color);

        createRoot(element).render(
          <AdvancedColorPicker
            activeColor={this.selectedColor}
            onColorSelect={color => this.handleColorSelect(color, close)}
            defaultColors={Object.values(colorObjects)}
            customColors={customColors}
            saveCustomColor={handleSaveCustomColor}
          />,
        );
        return element;
      },
    };
  }

  get allColors() {
    return [...Object.values(colorObjects), ...(this.jodit.options.customColors || [])];
  }

  initializeSelectedColor() {
    const caretColor = this.getCaretColor();

    if (!caretColor) {
      this.selectedColor = colorObjects.black;
      return;
    }

    const colorExists = Boolean(caretColor && this.allColors.some(c => c?.color === caretColor));

    if (!colorExists) {
      this.selectedColor = undefined;
    } else {
      if (this.selectedColor && this.selectedColor.color === caretColor) return;
      if (this.selectedColor && this.selectedColor.color !== caretColor) {
        this.selectedColor = this.allColors.find(c => c?.color === caretColor);
      } else {
        this.selectedColor = colorObjects.black;
      }
    }
  }

  getCaretColor() {
    let nodeWithStyle = this.jodit.selection.sel.focusNode;
    if (nodeWithStyle.nodeType === Node.TEXT_NODE) {
      nodeWithStyle = nodeWithStyle.parentNode;
    }

    return nodeWithStyle.style.color || null;
  }

  handleColorSelect(newColor, closePopup) {
    if (this.selectedColor?.color !== newColor.color) {
      this.jodit.execCommand('foreColor', false, newColor.color);
    }
    this.jodit.selection.focus();
    this.setIndicatorColor(newColor.color);
    this.selectedColor = newColor;
    closePopup();
  }

  setIndicatorColor(color) {
    const indicator = document.getElementById('text-editor-font-color-indicator');
    if (indicator) indicator.style.setProperty('--accent-color', color);
  }
}

Jodit.plugins.add('fontcolor', FontColorPlugin);
