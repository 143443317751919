import React from 'react';
import { string, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export default function TranslationButton(props) {
  const { label = '', onClick, disabled = false, className, ...rest } = props;
  const [t] = useTranslation();
  return (
    <button
      type="button"
      className={classNames('pbb-btn', {
        'pbb-btn--disabled': disabled,
        [className]: className,
      })}
      onClick={onClick}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {t(label)}
    </button>
  );
}

TranslationButton.propTypes = {
  label: string,
  onClick: func.isRequired,
  disabled: bool,
  className: string,
};
