import React, { useState, useReducer, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { func, shape, bool, string, number, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ShareManager } from '@pelckmans/business-components/components/share-manager';
import GenericModal from '../common/GenericModal';
import Tabs from '../tabs/tabs';
import Tab from '../tabs/tab';
import { getNodeString, getNodeTitle } from '../PrintPageRangeModal';
import PrintPages from '../../../../../components/print-pages';
import { closeDialog } from '../../../../../actions/dialog';
import { CustomSelectbox } from '../../../../../components/CustomSelectbox';
import { CustomRadioButton } from '../../../../../components/CustomRadioButton';
import CustomSelect from '../../../../../components/custom-select';
import dialogTypes from '../../../../../enums/dialogTypes';
import { tokenGetter } from '../../../../../services/api';
import { getFeatureToggleFor } from '../../../../../utils/feature-toggle';
import FeatureToggle from '../../../../../enums/feature-toggle';
import { STUDIO_GROUP } from '../../../../../constants/constants';
import Alert from '../../../../../components/alert';
import { getTeacherSharedSolutionNodes } from '../../../../../actions/sharing';
import { errorOccurred } from '../../../../../actions/error';
import getMyClassesLink from '../../../../../utils/getMyClassesLink';

const range = (start, end) => Array.from({ length: end + 1 - start }, (v, k) => k + start);

const tabs = {
  PRINT: 'print',
  SHARING_SOLUTIONS: 'sharing_solutions',
};

export default function TocNodeOptionsModal({ dispatch, node, digibook, activeTab }) {
  const isSharingEnabled = getFeatureToggleFor(FeatureToggle.SHARING_MATERIAL) && digibook.teacherDigitalManualLicense;
  const multiPagePrintsWithoutAnswersEnabled = getFeatureToggleFor(FeatureToggle.MULTI_PAGE_PRINTS_WITHOUT_ANSWERS);

  const isTocNodeMappedToPages = Object.prototype.hasOwnProperty.call(node, 'fromPage') && Object.prototype.hasOwnProperty.call(node, 'toPage');
  const allowedToPrint = isTocNodeMappedToPages && (digibook.teacherDigitalManualLicense || !digibook.teacherDemoProductLicense);

  const { t } = useTranslation();

  const renderShareManager = isTocNodeMappedToPages && digibook.answerLayer !== undefined;

  const [currentTab, setCurrentTab] = useState(isSharingEnabled ? tabs.SHARING_SOLUTIONS : tabs.PRINT);
  const [printOpts, setPrintOpts] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(!isSharingEnabled || (isSharingEnabled && !renderShareManager));

  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  const initialState = {
    includeAnswerLayer: !!digibook.answerLayer,
    checkedRadio: digibook.answerLayer ? 'wholeNode' : 'specificPage',
    fromPageSelectValue: node.fromPage,
    toPageSelectValue: node.toPage,
    renderingPagesToPrint: false,
  };

  const closeSelf = useCallback(() => dispatch(closeDialog(undefined, dialogTypes.TOCNODE_OPTIONS)), [dispatch]);
  const showModal = useCallback(() => setIsModalVisible(true), []);
  const handleError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in share manager component' })), [dispatch]);

  function reducer(state, action) {
    switch (action.type) {
      case 'toggle-include-answerLayer': {
        const answerLayerIncluded = !state.includeAnswerLayer;

        const newCheckedRadio = answerLayerIncluded ? 'wholeNode' : 'specificPage';

        const checkedRadio = multiPagePrintsWithoutAnswersEnabled ? state.checkedRadio : newCheckedRadio;

        return {
          ...state,
          includeAnswerLayer: answerLayerIncluded,
          checkedRadio,
        };
      }
      case 'set-range-wholeNode': {
        return { ...state, checkedRadio: 'wholeNode' };
      }
      case 'set-range-specficRange': {
        return { ...state, checkedRadio: 'range' };
      }
      case 'set-range-specificPage': {
        return { ...state, checkedRadio: 'specificPage' };
      }
      case 'change-fromPage': {
        return { ...state, fromPageSelectValue: parseInt(action.value, 10) };
      }
      case 'change-toPage': {
        return { ...state, toPageSelectValue: parseInt(action.value, 10) };
      }
      case 'renderingStarted': {
        return { ...state, renderingPagesToPrint: true };
      }
      default:
        return state;
    }
  }

  const [state, localDispatch] = useReducer(reducer, initialState);

  async function print() {
    if (state.renderingPagesToPrint) return;

    switch (state.checkedRadio) {
      case 'wholeNode': {
        localDispatch({ type: 'renderingStarted' });
        setPrintOpts({ from: node.fromPage, to: node.toPage });
        break;
      }
      case 'range': {
        localDispatch({ type: 'renderingStarted' });
        setPrintOpts({ from: state.fromPageSelectValue, to: state.toPageSelectValue });
        break;
      }
      case 'specificPage': {
        localDispatch({ type: 'renderingStarted' });
        setPrintOpts({ from: state.fromPageSelectValue });
        break;
      }
      default:
    }
  }

  const getFooterContent = () => {
    switch (currentTab) {
      case tabs.PRINT:
        return allowedToPrint ? (
          <>
            <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
              {t('printing.modal.buttons.cancel')}
            </button>
            <button type="button" className={classNames('pbb-btn', { 'pbb-btn--busy': state.renderingPagesToPrint })} onClick={print}>
              {t('printing.modal.buttons.print')}
            </button>
          </>
        ) : (
          <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
            {t('printing.modal.buttons.close')}
          </button>
        );
      case tabs.SHARING_SOLUTIONS:
        if (isSharingEnabled && !renderShareManager) {
          return (
            <button type="button" className="pbb-btn pbb-btn--inverted" onClick={closeSelf}>
              {t('sharing.solutions.modal.buttons.close')}
            </button>
          );
        }
        return null;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  return (
    <div className={isModalVisible ? '' : 'pbb-modal-wrapper--hidden'}>
      <GenericModal
        title={getNodeTitle(node)}
        footerContent={getFooterContent()}
        icon="icon-book"
        tabBar={
          <Tabs onClick={setCurrentTab} activeTab={currentTab}>
            {isSharingEnabled && <Tab title={t('tabs.sharing_solutions')} icon="bc-icon bc-icon-lightbulb" id={tabs.SHARING_SOLUTIONS} />}
            <Tab title={t('tabs.print')} id={tabs.PRINT} icon="icon-bb-print" />
          </Tabs>
        }
        close={closeSelf}
        contentModifier={classNames('pbb-modal__tocNodeOptions', {
          'pbb-modal__tocNodeOptions--large': isSharingEnabled && renderShareManager,
        })}
      >
        {printOpts && <PrintPages digibook={digibook} from={printOpts.from} to={printOpts.to} includeAnswers={state.includeAnswerLayer} onAfterPrint={closeSelf} />}

        {currentTab === tabs.PRINT &&
          (digibook.teacherDemoProductLicense && !digibook.teacherDigitalManualLicense ? (
            <div className="pbb-print-container">
              <div className="pbb-alert">
                <span dangerouslySetInnerHTML={{ __html: t('printing.modal.demoProductDisclaimer') }} />
              </div>
            </div>
          ) : (
            <div className="pbb-print-container" data-testid="print-tab-content">
              {!isTocNodeMappedToPages && (
                <div className="pbb-alert">
                  <span data-testid="message" dangerouslySetInnerHTML={{ __html: t('printing.modal.notMapped') }} />
                </div>
              )}

              {isTocNodeMappedToPages && digibook.answerLayer && (
                <>
                  <CustomSelectbox id="include-answers" checked={state.includeAnswerLayer} onChange={() => localDispatch({ type: 'toggle-include-answerLayer' })}>
                    <span dangerouslySetInnerHTML={{ __html: t('printing.modal.includeAnswerLayers') }} />
                  </CustomSelectbox>
                  <div className="divider-line" />
                </>
              )}

              {isTocNodeMappedToPages && (multiPagePrintsWithoutAnswersEnabled || state.includeAnswerLayer) && (
                <>
                  <CustomRadioButton
                    value="wholeNode"
                    name="printRange"
                    onChange={() => localDispatch({ type: 'set-range-wholeNode' })}
                    checked={state.checkedRadio === 'wholeNode'}
                  >
                    {t('printing.modal.everythingOf')}
                    &nbsp;
                    {getNodeString(node)}
                  </CustomRadioButton>

                  {node.toPage > node.fromPage && (
                    <CustomRadioButton
                      value="range"
                      name="printRange"
                      onChange={() => localDispatch({ type: 'set-range-specficRange' })}
                      checked={state.checkedRadio === 'range'}
                      className="radio pbb-page-selection"
                    >
                      {t('printing.modal.page')}
                      &nbsp;
                      <div className="pbb-page-select">
                        <CustomSelect
                          testId="select-range-from"
                          onChange={e => {
                            localDispatch({ type: 'set-range-specficRange' });
                            localDispatch({ type: 'change-fromPage', value: e.target.value });
                          }}
                          value={state.fromPageSelectValue.toString()}
                        >
                          {range(node.fromPage, state.toPageSelectValue).map(i => (
                            <option key={`from-${i}`}>{i}</option>
                          ))}
                        </CustomSelect>
                      </div>
                      &nbsp; tot &nbsp;
                      <CustomSelect
                        testId="select-range-to"
                        onChange={e => {
                          localDispatch({ type: 'set-range-specficRange' });
                          localDispatch({ type: 'change-toPage', value: e.target.value });
                        }}
                        value={state.toPageSelectValue.toString()}
                      >
                        {range(state.fromPageSelectValue, node.toPage).map(i => (
                          <option key={`to-${i}`}>{i}</option>
                        ))}
                      </CustomSelect>
                    </CustomRadioButton>
                  )}
                </>
              )}

              {!multiPagePrintsWithoutAnswersEnabled && isTocNodeMappedToPages && !state.includeAnswerLayer && (
                <CustomRadioButton
                  value="specificPage"
                  name="printRange"
                  onChange={() => localDispatch({ type: 'set-range-specificPage' })}
                  checked={state.checkedRadio === 'specificPage'}
                  className="radio pbb-page-selection"
                >
                  {t('printing.modal.page')}
                  &nbsp;
                  <CustomSelect
                    testId="select-single"
                    onChange={e => {
                      localDispatch({ type: 'change-fromPage', value: e.target.value });
                    }}
                    value={state.fromPageSelectValue.toString()}
                  >
                    {range(node.fromPage, node.toPage).map(i => (
                      <option key={`from-${i}`}>{i}</option>
                    ))}
                  </CustomSelect>
                </CustomRadioButton>
              )}
            </div>
          ))}

        {currentTab === tabs.SHARING_SOLUTIONS && isSharingEnabled && !renderShareManager && (
          <div>
            {/* the wrapper element inherits the height of it's parent, therefor a wrapper is needed here */}
            <Alert message={t('sharing.solutions.modal.notMapped')} />
          </div>
        )}

        {isSharingEnabled && renderShareManager && (
          <div className={classNames('pbb-share-manager', { 'pbb-share-manager--hidden': currentTab !== tabs.SHARING_SOLUTIONS })}>
            <ShareManager
              moduleId={digibook.module}
              nodeId={node.id}
              materialType="solution"
              materialId={digibook.id}
              tocNodeRange={{ from: node.fromPage, to: node.toPage }}
              tokenAccessor={tokenAccessor}
              onCancel={closeSelf}
              onSave={() => {
                closeSelf();
                dispatch(getTeacherSharedSolutionNodes());
              }}
              onError={handleError}
              onGroupsFetched={showModal}
              translations={{
                sharingDisabled: t('shareManager.sharingDisabled'),
                sharingEnabled: t('shareManager.sharingEnabled'),
                cancel: t('shareManager.cancel'),
                save: t('shareManager.save'),
                rangeSeparator: t('shareManager.rangeSeparator'),
                overrideRangeWarning: t('shareManager.overrideRangeWarning'),
                noGroupsMessage: t('shareManager.solutions.noGroupsMessage', {
                  myClassesLink: getMyClassesLink(),
                }),
                close: t('shareManager.close'),
                search: t('shareManager.search'),
                noSearchResults: t('shareManager.noSearchResults'),
              }}
              studioGroup={STUDIO_GROUP}
            />
          </div>
        )}
      </GenericModal>
    </div>
  );
}

TocNodeOptionsModal.propTypes = {
  node: shape({
    id: string.isRequired,
    fromPage: number,
    toPage: number,
    title: string,
    displayPrefix: bool,
    prefix: string,
  }).isRequired,
  digibook: shape({
    answerLayer: string,
    systemToken: string,
    totalPages: number,
    teacherDemoProductLicense: bool,
    teacherDigitalManualLicense: bool,
  }).isRequired,
  dispatch: func.isRequired,
  activeTab: oneOf([tabs.PRINT, tabs.SHARING_SOLUTIONS]),
};
